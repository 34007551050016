import { Collapse, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useContext } from 'react'
//
import {
  Advantages,
  Compensation,
  //Faq,
  Footer,
  Newsletter,
  SingleText,
  Opportunities,
} from './components'
import Co2Context from '../context/co2.context'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#FFF !important',
    margin: '0 auto',
    width: '100%',
    minWidth: '800px',
    maxWidth: '1920px',
    maxHeight: '1080px',
  },
}))

export const Co2Calculator = () => {
  const classes = useStyles()
  const data = useContext(Co2Context)
  const {
    advantages,
    compensation,
    /*faq,*/ footer,
    opportunities,
    text,
  } = data

  return (
    <Paper className={classes.root} elevation={0}>
      {/* Single Text */}
      {text && (
        <Collapse in={true}>
          <SingleText data={text} />
        </Collapse>
      )}

      {/* Advantages */}
      {advantages && (
        <Collapse in={true}>
          <Advantages data={advantages} />
        </Collapse>
      )}

      {/* Compensation */}
      {compensation && (
        <Collapse in={true}>
          <Compensation data={compensation} />
        </Collapse>
      )}

      {/* Opportunities */}
      {opportunities && (
        <Collapse in={true}>
          <Opportunities data={opportunities} />
        </Collapse>
      )}

      {/* FAQ */}
      {/*faq && (
        <Collapse in={true}>
          <Faq data={faq} title={'FAQ CO₂-Kompensation'} />
        </Collapse>
      )*/}

      {/* Newsletter */}
      <Newsletter />

      {/* Footer */}
      <Footer data={footer} />
    </Paper>
  )
}
