// modules
import { Collapse, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useContext } from 'react'
// scripts
import {
  Benefits,
  //BlogList,
  Company,
  Expectations,
  Footer,
  Newsletter,
  SingleText,
} from './components'
// context
import HomeContext from '../context/home.context'
//import BlogContext from '../context/blog.context'
// assets
import { ICO_QUESTION, ICO_QUOTE } from '../img'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#FFF !important',
    margin: '0 auto',
    width: '100%',
    minWidth: '800px',
    maxWidth: '1920px',
    maxHeight: '1080px',
  },
}))

export const Home = () => {
  const classes = useStyles()
  const data = useContext(HomeContext)
  //const blogData = useContext(BlogContext)
  const { benefits, company, expectations, footer, text } = data

  return (
    <Paper className={classes.root} elevation={0}>
      {/* Single Text */}
      {text && (
        <Collapse in={true}>
          <SingleText data={text} icon={ICO_QUOTE} />
        </Collapse>
      )}

      {/* Benefits */}
      {benefits && (
        <Collapse in={true}>
          <Benefits data={benefits} />
        </Collapse>
      )}

      {/* Expectations */}
      {expectations && (
        <Collapse in={true}>
          <Expectations data={expectations} icon={ICO_QUESTION} />
        </Collapse>
      )}

      {/* Company */}
      {company && (
        <Collapse in={true}>
          <Company data={company} />
        </Collapse>
      )}

      {/* BlogList */}
      {/*blogData && (
        <Collapse in={true}>
          <BlogList data={blogData} />
        </Collapse>
      )*/}

      {/* Newsletter */}
      <Newsletter />

      {/* Footer */}
      <Footer data={footer} />
    </Paper>
  )
}
