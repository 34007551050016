import { Button, Container, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React from 'react'
// assets
import { ICO_DOWNLOAD } from '../../img'

const useStyles = makeStyles(() => ({
  root: {
    background: '#F4F2F2',
    width: '100%',
    height: '250px',
    position: 'relative',
  },
  title: {
    font: '40px RobotoSlab !important',
    paddingTop: '50px !important',
    marginBottom: '20px !important',
  },
  description: {
    font: '20px Roboto !important',
    maxWidth: '476px',
    textAlign: 'justify',
  },
  mandate: {
    left: '650px',
    top: '-100px',
    color: 'black !important',
    font: '20px Roboto !important',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  checklist: {
    left: '850px',
    top: '-100px',
    color: 'black !important',
    font: '20px Roboto !important',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}))

export const Downloads = props => {
  const classes = useStyles()
  const { REACT_APP_BASE_URL: BASE_URL } = process.env

  const { items } = props.data

  return (
    <div className={classes.root}>
      <Container maxWidth={'lg'}>
        <Typography className={classes.title}>Downloads</Typography>
        <Typography className={classes.description}>
          Nachfolgend haben wir die wichtigsten Formulare, die für einen
          professionellen Energieeinkauf notwendig sind, als PDF-Download zur
          Verfügung gestellt.
        </Typography>
        <Button
          className={classes.mandate}
          //onClick={() => (window.location = `${BASE_URL}${items[0].media.url}`)}
          onClick={() => window.open(`${BASE_URL}${items[0].media.url}`)}
        >
          <img src={ICO_DOWNLOAD} alt={''} />
          {items[0].title}
        </Button>
        <Button
          className={classes.checklist}
          //onClick={() => (window.location = `${BASE_URL}${items[1].media.url}`)}
          onClick={() => window.open(`${BASE_URL}${items[1].media.url}`)}
        >
          <img src={ICO_DOWNLOAD} alt={''} />
          {items[1].title}
        </Button>
      </Container>
    </div>
  )
}

Downloads.propTypes = {
  data: PropTypes.any,
}
