import { Collapse, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useContext } from 'react'
//
import {
  AuditDIN,
  AdvantagesOA,
  Contact,
  //Faq,
  Footer,
  Newsletter,
  SingleText,
} from './components'
import AuditContext from '../context/audit.context'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#FFF !important',
    margin: '0 auto',
    width: '100%',
    minWidth: '800px',
    maxWidth: '1920px',
    maxHeight: '1080px',
  },
}))

export const Audit = () => {
  const classes = useStyles()
  const data = useContext(AuditContext)
  const { advantages, auditDin, contact, /*faq,*/ footer, text } = data

  return (
    <Paper className={classes.root} elevation={0}>
      {/* SingleText */}
      {text && (
        <Collapse in={true}>
          <SingleText data={text} />
        </Collapse>
      )}

      {/* AuditDIN */}
      {auditDin && (
        <Collapse in={true}>
          <AuditDIN data={auditDin} />
        </Collapse>
      )}

      {/* AdvantagesOA */}
      {advantages && (
        <Collapse in={true}>
          <AdvantagesOA data={advantages} />
        </Collapse>
      )}

      {/* Contact */}
      {contact && (
        <Collapse in={true}>
          <Contact data={contact} />
        </Collapse>
      )}

      {/* FAQ */}
      {/*faq && (
        <Collapse in={true}>
          <Faq data={faq} title={'FAQ Online Audit'} />
        </Collapse>
      )*/}

      {/* Newsletter */}
      <Newsletter />

      {/* Footer */}
      <Footer data={footer} />
    </Paper>
  )
}
