import {
  Card,
  CardContent,
  Container,
  ImageList,
  ImageListItem,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React from 'react'
//
import { ICO_LION } from '../../img'

const useStyles = makeStyles(() => ({
  root: {
    background: '#FFF',
    paddingTop: '200px',
    paddingBottom: '200px',
  },
  title: {
    font: '40px RobotoSlab !important',
    marginBottom: '20px',
  },
  content: {
    color: 'white',
    width: '498px',
    height: '440px',
    background: 'lightgray',
  },
  imageList: {
    marginLeft: '200px',
  },
  thumbnail: {
    filter:
      'invert(98%) sepia(1%) saturate(2%) hue-rotate(57deg) brightness(114%) contrast(100%)',
    width: '200px',
  },
}))

export const Faq = (props) => {
  const classes = useStyles()
  const { title } = props

  const dummyData = [
    { title: 'Lorem ipsum dolor sit amgt.', description: '12. Juni 2001' },
    { title: 'Lorem ipsum dolor sit amgt.', description: '12. Juni 2001' },
    { title: 'Lorem ipsum dolor sit amgt.', description: '12. Juni 2001' },
    { title: 'Lorem ipsum dolor sit amgt.', description: '12. Juni 2001' },
  ]

  return (
    <div className={classes.root}>
      <Container>
        <Typography className={classes.title}>{title}</Typography>
      </Container>

      <ImageList
        className={classes.imageList}
        xl={{ width: 1920, height: 440 }}
        cols={4}
        rowHeight={440}
      >
        {dummyData.map((item, key) => {
          const { description, title } = item

          return (
            <ImageListItem key={key}>
              <Card style={{ position: 'relative' }}>
                <CardContent className={classes.content}>
                  {title}
                  {description}
                  <div className={classes.thumbnail}>
                    <img src={ICO_LION} alt={''} width={200} height={'auto'} />
                  </div>
                </CardContent>
              </Card>
            </ImageListItem>
          )
        })}
      </ImageList>
    </div>
  )
}

Faq.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
}
