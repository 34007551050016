// sessionStorage

export const getSession = key => JSON.parse(sessionStorage.getItem(key))

export const setSession = (key, value) =>
  sessionStorage.setItem(key, JSON.stringify(value))

export const removeSession = key => sessionStorage.removeItem(key)

// localStorage

export const getStorage = key => JSON.parse(localStorage.getItem(key))

export const setStorage = (key, value) =>
  localStorage.setItem(key, JSON.stringify(value))

export const removeStorage = key => localStorage.removeItem(key)
