import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { ICO_ARROW_DOWN } from '../../img'

const useStyles = makeStyles(() => ({
  root: {
    background: '#FFF',
    paddingTop: '200px !important',
    paddingBottom: '100px',
  },
  title: {
    font: '40px RobotoSlab !important',
    marginBottom: '20px !important',
  },
  description: {
    font: '20px Roboto !important',
    marginBottom: '20px !important',
    marginLeft: '20px !important',
    marginRight: '20px !important',
    display: 'flex',
    textAlign: 'justify',
    hyphens: 'auto',
  },
  accordion: {
    borderTop: 'solid 1px #707070 !important',
    borderBottom: 'solid 1px #707070',
  },
  accordionSummary: {
    font: '40px Roboto !important',
    paddingBottom: '20px !important',
    paddingTop: '20px !important',
  },
  details: {
    font: '20px Roboto !important',
    maxWidth: '730px !important',
    textAlign: 'justify',
  },
}))

export const Opportunities = props => {
  const classes = useStyles()
  const { data } = props
  const { description, items, title } = data

  const [active, setActive] = useState(null)

  return data ? (
    <div className={classes.root}>
      <Container>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.description}>{description}</Typography>

        <div className={classes.accordion}>
          {items.map((item, key) => {
            const { description, title } = item

            return (
              <Accordion
                disableGutters
                elevation={0}
                expanded={active === key}
                key={key}
                onChange={() => setActive(key)}
                style={{ backgroundColor: 'transparent' }}
                sx={{
                  '&.Mui-expanded': {
                    minHeight: 0,
                    margin: '12px 0',
                    borderTop: 'solid 1px #707070',
                  },
                  '& .MuiAccordionSummary-content.Mui-expanded': {
                    margin: 0,
                  },
                }}
              >
                <AccordionSummary
                  className={classes.accordionSummary}
                  expandIcon={<img src={ICO_ARROW_DOWN} alt={''} />}
                >
                  {title}
                </AccordionSummary>

                <AccordionDetails className={classes.details}>
                  {description}
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>
      </Container>
    </div>
  ) : (
    ''
  )
}

Opportunities.propTypes = {
  data: PropTypes.object,
}
