import {
  Button,
  Card,
  CardContent,
  Collapse,
  ImageList,
  ImageListItem,
  Paper,
  Typography,
} from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useRef, useState } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { routes } from '../routes'
import { useFetch } from '../hooks/useFetch'
// context
import { FormDataProvider } from '../context/formData.context'
import { PriceConditionsProvider } from '../context/priceConditions.context'
import { HomeProvider } from '../context/home.context'
import { EnergyProvider } from '../context/energy.context'
import { GasProvider } from '../context/gas.context'
import { Co2Provider } from '../context/co2.context'
import { AuditProvider } from '../context/audit.context'
import { GrantsProvider } from '../context/grants.context'
import { CompanyProvider } from '../context/company.context'
import { PrivacyProvider } from '../context/privacy.context'
import { ImpressumProvider } from '../context/impressum.context'
import { BlogProvider } from '../context/blog.context'
//
import {
  CalcTypeSelector,
  CommonData,
  EnergyOffers,
  GasOffers,
  Header,
  Scene,
  Viewport,
} from '../pages/components'
import { getStorage, setStorage } from '../api/storage'
// theme
import { theme } from '../pages/score24.theme'
// assets
import { ICO_ARROW_UP, ICO_TRANSPARENT } from '../img'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#FFF !important',
    margin: '0 auto',
    width: '100%',
    minWidth: '800px',
    maxWidth: '1920px',
    maxHeight: '100%',
  },

  cookieRoot: {
    position: 'absolute',
    zIndex: '999999',
  },
  cookieNote: {
    position: 'fixed',
    bottom: '0',
    backgroundColor: '#A00523 !important',
    color: 'white',
    width: '100%',
    padding: '10px',
  },
  cookieButton: {
    borderRadius: '0 !important',
    textTransform: 'none !important',
    backgroundColor: 'white !important',
    color: '#A00523 !important',
    width: '300px',
    padding: '5px !important',
    margin: '0px 5px !important',
    font: '20px Roboto !important',
  },

  slogan: {
    color: 'white',
    font: '60px RobotoSlab !important',
    height: '300px',
    left: '200px',
    position: 'absolute',
    top: '180px',
    width: '760px',
  },

  description: {
    color: '#FFF',
    font: '20px Roboto !important',
    left: '200px',
    position: 'absolute',
    top: '360px',
    width: '660px',
  },

  home_root: { position: 'absolute', right: '0' },
  container_goto_home: {
    position: 'fixed',
    marginLeft: '-90px',
    top: '500px',
    zIndex: '10000',
    backgroundImage: `url(${ICO_TRANSPARENT})`,
    backdropFilter: `blur(50px)`,
  },

  button_goto_home: {
    width: '90px',
    height: '60px',
  },

  content: {
    padding: '0px !important',
    margin: '0px',
  },

  imageList: {
    overflowX: 'hidden',
    scroll: 'no',
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',

    // Hide Scrollbar
    '-ms-overflow-style': 'none' /* IE and Edge */,
    'scrollbar-width': 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      /* Chrome */
      display: 'none',
    },
    marginBottom: '0px',
  },
}))

export const Layout = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const { REACT_APP_BASE_URL: BASE_URL } = process.env

  const YEAR = new Date().getFullYear()
  const MONTH = new Date().getMonth()

  const topRef = useRef(null)
  const bigPicRef = useRef(null)

  const STORAGE_ID = 'client_dsgvo_ack'
  const [dsgvoAck, setDsgvoAck] = useState(Boolean(getStorage(STORAGE_ID)))

  const {
    loading: priceLoading,
    error: priceError,
    data: priceData,
  } = useFetch('/price-data')

  const [formData, setFormData] = useState({
    amount: 'Jahresbedarf',
    branch: 0,
    finalPrices: [0, 0, 0],
    medium: 'energy',
    showEnergyOffer: false,
    showGasOffer: false,
    showCo2Offer: false,
    startDate: new Date(YEAR, MONTH, 1),
  })

  const { loading: homeLoading, error: homeError, data: homeData } = useFetch(
    '/homepage'
  )

  const {
    loading: energyLoading,
    error: energyError,
    data: energyData,
  } = useFetch('/energy-calculator')

  const { loading: gasLoading, error: gasError, data: gasData } = useFetch(
    '/gas-calculator'
  )

  const { loading: co2Loading, error: co2Error, data: co2Data } = useFetch(
    '/co-2-calculator'
  )

  const {
    loading: auditLoading,
    error: auditError,
    data: auditData,
  } = useFetch('/online-audit')

  const {
    loading: grantsLoading,
    error: grantsError,
    data: grantsData,
  } = useFetch('/foerdermittel')

  const {
    loading: companyLoading,
    error: companyError,
    data: companyData,
  } = useFetch('/unternehmen')

  // blog
  const { loading: blogLoading, error: blogError, data: blogData } = useFetch(
    '/blog'
  )

  const {
    loading: privacyLoading,
    error: privacyError,
    data: privacyData,
  } = useFetch('/privacy')

  const {
    loading: impressumLoading,
    error: impressumError,
    data: impressumData,
  } = useFetch('/impressum')

  const isPageLoading =
    priceLoading ||
    homeLoading ||
    energyLoading ||
    gasLoading ||
    co2Loading ||
    auditLoading ||
    grantsLoading ||
    companyLoading ||
    blogLoading ||
    privacyLoading ||
    impressumLoading

  const isPageError =
    priceError ||
    homeError ||
    energyError ||
    gasError ||
    co2Error ||
    auditError ||
    grantsError ||
    companyError ||
    blogError ||
    privacyError ||
    impressumError

  if (isPageError) return isPageError

  const [bigPics, setBigPics] = useState([])
  const [curBigPic, setCurBigPic] = useState(null)

  // handle bigPics
  useEffect(
    () => {
      if (!isPageLoading) {
        const boundingWidth = 1920

        const __bigPics = [
          {
            location: '/',
            header: homeData.header,
            left: boundingWidth * 0,
            height: 655,
          },
          {
            location: 'energy-calculator',
            header: energyData.header,
            left: boundingWidth * 1,
            height: 510,
          },
          {
            location: 'gas-calculator',
            header: gasData.header,
            left: boundingWidth * 2,
            height: 510,
          },
          {
            location: 'co2-calculator',
            header: co2Data.header,
            left: boundingWidth * 3,
            height: 510,
          },
          {
            location: 'audit',
            header: auditData.header,
            left: boundingWidth * 4,
            height: 510,
          },
          {
            location: 'foerdermittel',
            header: grantsData.header,
            left: boundingWidth * 5,
            height: 510,
          },
          {
            location: 'unternehmen',
            header: companyData.header,
            left: boundingWidth * 6,
            height: 510,
          },
          {
            location: 'blog',
            header: blogData.header,
            left: boundingWidth * 7,
            height: 510,
          },
        ]

        setBigPics(__bigPics)
        setCurBigPic(__bigPics.find(f => f.location === location.pathname))
      }
    },
    [isPageLoading]
  )

  // update bigPic based on url
  useEffect(
    () => {
      const { pathname } = location

      // set scrollLeft of imageList to change bigPic
      if (bigPicRef.current) {
        if (pathname === '/') bigPicRef.current.scrollLeft = 0
        else {
          const match = bigPics.find(
            f => f.location === pathname.replace('/', '')
          )

          if (match) {
            bigPicRef.current.scrollLeft = match.left
            setCurBigPic(match)
          }
        }
      }

      // change calculator medium based on pathname
      if (location.pathname === '/energy-calculator') {
        setFormData({ ...formData, medium: 'energy' })
      }

      if (location.pathname === '/gas-calculator') {
        setFormData({ ...formData, medium: 'gas' })
      }

      if (location.pathname === '/co2-calculator') {
        setFormData({ ...formData, medium: 'co2' })
      }
    },
    [location.pathname]
  )

  // on location change scroll to top
  useEffect(
    () => {
      if (topRef.current)
        topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    },
    [location.pathname]
  )

  useEffect(
    () => {
      if (isPageError) return <>OJE! {isPageError}</>
    },
    [isPageError]
  )

  useEffect(
    () => {
      setStorage(STORAGE_ID, dsgvoAck)
    },
    [dsgvoAck]
  )

  return isPageLoading ? (
    <div>lädt ...</div>
  ) : (
    <PriceConditionsProvider value={priceData}>
      {/* context */}
      <FormDataProvider value={{ formData, setFormData }}>
        <HomeProvider value={homeData}>
          <EnergyProvider value={energyData}>
            <GasProvider value={gasData}>
              <Co2Provider value={co2Data}>
                <AuditProvider value={auditData}>
                  <GrantsProvider value={grantsData}>
                    <CompanyProvider value={companyData}>
                      <BlogProvider value={blogData}>
                        <PrivacyProvider value={privacyData}>
                          <ImpressumProvider value={impressumData}>
                            {/* viewport (browser window) */}
                            <Viewport>
                              {dsgvoAck !== true && (
                                <div className={classes.cookieRoot}>
                                  <Typography className={classes.cookieNote}>
                                    Württembergische Energie GmbH verwendet
                                    Cookies, um diese Website Ihren Bedürfnissen
                                    anzupassen.{' '}
                                    <Button
                                      className={classes.cookieButton}
                                      onClick={() =>
                                        history.push('/datenschutz')
                                      }
                                    >
                                      Details ansehen
                                    </Button>
                                    <Button
                                      className={classes.cookieButton}
                                      onClick={() => setDsgvoAck(true)}
                                    >
                                      Zustimmen
                                    </Button>
                                  </Typography>
                                </div>
                              )}

                              <Scene>
                                {/* theme */}
                                <ThemeProvider theme={theme}>
                                  {/* page root */}
                                  <Paper
                                    className={classes.root}
                                    elevation={0}
                                    ref={topRef}
                                  >
                                    {/* header */}
                                    <Header />
                                    {/* bigPic */}
                                    <Card style={{ position: 'relative' }}>
                                      {!location.pathname.includes('/blog') && (
                                        <ImageList
                                          className={classes.imageList}
                                          cols={bigPics.length}
                                          gap={0}
                                          rowHeight={
                                            location.pathname === '/'
                                              ? 655
                                              : 510
                                          }
                                          sx={{ width: 1920, height: 510 }}
                                          ref={bigPicRef}
                                        >
                                          {/* big pics nebeneinander */}
                                          {bigPics.map((item, key) => {
                                            const { header } = item
                                            if (!header)
                                              return <div key={key} />

                                            const { picture } = header

                                            const title =
                                              curBigPic?.header.title
                                            const description =
                                              curBigPic?.header.description

                                            return (
                                              <ImageListItem key={key}>
                                                <Card
                                                  style={{
                                                    position: 'relative',
                                                  }}
                                                >
                                                  <CardContent
                                                    className={classes.content}
                                                  >
                                                    <Typography
                                                      className={classes.slogan}
                                                    >
                                                      {title}
                                                    </Typography>
                                                    <Typography
                                                      className={
                                                        classes.description
                                                      }
                                                    >
                                                      {description}
                                                    </Typography>
                                                    {picture &&
                                                      picture.url && (
                                                        <img
                                                          src={`${BASE_URL}${
                                                            picture.url
                                                          }`}
                                                          alt={''}
                                                          style={{
                                                            height: 'auto',
                                                            width: 1920,
                                                          }}
                                                        />
                                                      )}
                                                  </CardContent>
                                                </Card>
                                              </ImageListItem>
                                            )
                                          })}
                                        </ImageList>
                                      )}

                                      {/* to Top Button */}
                                      <div className={classes.home_root}>
                                        <div
                                          className={
                                            classes.container_goto_home
                                          }
                                        >
                                          <Button
                                            className={classes.button_goto_home}
                                            onClick={() => {
                                              // Safari
                                              document.body.scrollTop = 0
                                              // Chrome, Firefox, IE, Opera
                                              document.documentElement.scrollTop = 0

                                              topRef.current.scrollIntoView({
                                                behavior: 'smooth',
                                                block: 'start',
                                              })
                                            }}
                                          >
                                            <img alt={''} src={ICO_ARROW_UP} />
                                          </Button>
                                        </div>
                                      </div>
                                    </Card>

                                    {/* calculation form */}
                                    <Collapse
                                      in={
                                        location.pathname === '/' ||
                                        location.pathname ===
                                          '/energy-calculator' ||
                                        location.pathname ===
                                          '/gas-calculator' ||
                                        location.pathname === '/co2-calculator'
                                      }
                                    >
                                      <CalcTypeSelector
                                        medium={formData.medium}
                                      />
                                    </Collapse>
                                    {/* calculation results */}

                                    {/* EnergyOffers */}
                                    <Collapse
                                      in={
                                        formData.medium === 'energy' &&
                                        formData.showEnergyOffer
                                      }
                                    >
                                      <EnergyOffers />
                                    </Collapse>

                                    {/* GasOffers */}
                                    <Collapse
                                      in={
                                        formData.medium === 'gas' &&
                                        formData.showGasOffer
                                      }
                                    >
                                      <GasOffers />
                                    </Collapse>

                                    {/* Co2Offers */}
                                    <Collapse
                                      in={
                                        formData.medium === 'co2' &&
                                        formData.showCo2Offer
                                      }
                                    >
                                      <CommonData />
                                    </Collapse>

                                    {/* switch by content */}
                                    <Switch>
                                      {routes.map((route, key) => {
                                        const { component, path } = route

                                        return (
                                          <Route
                                            component={component}
                                            key={key}
                                            path={path}
                                            strict
                                          />
                                        )
                                      })}
                                    </Switch>
                                  </Paper>
                                </ThemeProvider>
                              </Scene>
                            </Viewport>
                          </ImpressumProvider>
                        </PrivacyProvider>
                      </BlogProvider>
                    </CompanyProvider>
                  </GrantsProvider>
                </AuditProvider>
              </Co2Provider>
            </GasProvider>
          </EnergyProvider>
        </HomeProvider>
      </FormDataProvider>
    </PriceConditionsProvider>
  )
}
