import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

export const Viewport = styled.div`
  position: ${props => props.position};
  width: ${props => props.width};
  height: ${props => props.height};
  left: ${props => props.left};
  top: ${props => props.top};
  background-color: ${props => props.bgColor};
  overflow: ${props => props.overflow};
`

Viewport.defaultProps = {
  /* dimension */
  width: '100vw',
  height: '100vh',
  /* position */
  position: 'relative',
  left: '0px',
  top: '0px',
  /* backgroundColor */
  bgColor: 'white',
  /* overflow */
  overflow: 'hidden',
}

Viewport.propTypes = {
  children: PropTypes.any.isRequired,
}
