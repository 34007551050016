import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
//
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
//
import { ICO_ARROW_DOWN } from '../../img'
import { useOnScreen } from '../../hooks/useOnScreen'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '150px 0',
    background: '#FFF',
    position: 'relative',
  },
  container: {
    width: '100%',
    maxWidth: '1194px',
  },
  caption: {
    borderBottom: 'solid 2px #FFFFFF',
    font: '60px RobotoSlab !important',
    paddingBottom: '50px',
    paddingLeft: '20px',
  },
  accordion: {
    borderTop: 'solid 1px #707070 !important',
    borderBottom: 'solid 1px #707070',
  },
  accordionSummary: {
    font: '40px Roboto !important',
    paddingBottom: '20px !important',
    paddingTop: '20px !important',
  },
  details: {
    font: '20px Roboto !important',
    maxWidth: '745px !important',
    textAlign: 'justify',
  },
  scaleQuote: {
    position: 'absolute',
    right: '200px',
    marginTop: '-30px',
    zIndex: '0',
    transform: 'scale(0.8)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
  },
  scaleQuoteDown: {
    position: 'absolute',
    right: '200px',
    marginTop: '-30px',
    zIndex: '0',
    transform: 'scale(1)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
  },
}))

export const Expectations = props => {
  const classes = useStyles()
  const { data, icon } = props
  const { items, title } = data

  const [active, setActive] = useState(null)

  const ref = useRef()
  const isVisible = useOnScreen(ref)

  return data ? (
    <div className={classes.root}>
      {/* render icon if provided */}
      {icon && (
        <img
          alt={''}
          className={isVisible ? classes.scaleQuoteDown : classes.scaleQuote}
          src={icon}
        />
      )}

      <Container className={classes.container}>
        <Typography ref={ref} className={classes.caption}>
          {title}
        </Typography>

        <div className={classes.accordion}>
          {items.map((item, key) => {
            const { description, title } = item

            return (
              <Accordion
                disableGutters
                elevation={0}
                expanded={active === key}
                key={key}
                onChange={() => setActive(key)}
                style={{ backgroundColor: 'transparent' }}
                sx={{
                  '&.Mui-expanded': {
                    minHeight: 0,
                    margin: '12px 0',
                    borderTop: 'solid 1px #707070',
                  },
                  '& .MuiAccordionSummary-content.Mui-expanded': {
                    margin: 0,
                  },
                }}
              >
                <AccordionSummary
                  className={classes.accordionSummary}
                  expandIcon={<img src={ICO_ARROW_DOWN} alt={''} />}
                >
                  {title}
                </AccordionSummary>

                <AccordionDetails className={classes.details}>
                  {description}
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>
      </Container>
    </div>
  ) : (
    ''
  )
}

Expectations.propTypes = {
  data: PropTypes.object,
  icon: PropTypes.string,
}
