import {
  Breadcrumbs,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { AddCircle } from '@mui/icons-material'
import { Alert } from '@mui/lab'
import { makeStyles } from '@mui/styles'
import matchSorter from 'match-sorter'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import Chart from 'react-google-charts'
//import ReactJson from 'react-json-view'
import { useHistory } from 'react-router-dom'
import ReactTable from 'react-table'
import { useFetch } from '../hooks/useFetch'
import COMPENSATION_0 from '../img/placeholder.png'
import COMPENSATION_1 from '../img/placeholder.png'
import COMPENSATION_2 from '../img/placeholder.png'
import COMPENSATION_3 from '../img/placeholder.png'

import jsonData from '../data/CF2020.json'

const useStyles = makeStyles(() => ({
  header: { marginTop: '50px', marginBottom: '50px' },
  root: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px',
    paddingRight: '0px',
  },
  locations: {
    marginTop: '50px',
    marginBottom: '30px',
  },
  button: {
    marginLeft: '50px',
  },
  formControl: {
    marginTop: '30px',
    marginBottom: '30px',
  },
  grid: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  section: { backgroundColor: 'grey', padding: '10px' },
  bold: { fontWeight: 'bold' },
  card: { margin: '10px', textAlign: 'center' },
  cardSelected: {
    margin: '10px',
    textAlign: 'center',
    backgroundColor: 'green',
  },
}))

const ScopeFilter = ({ filter, onChange }) => (
  <Select
    onChange={e => onChange(e.target.value)}
    //style={{ width: '100%' }}
    value={filter ? filter.value : 'all'}
  >
    <MenuItem value={null}>Alle</MenuItem>
    <MenuItem value={'Scope 1'}>Scope 1</MenuItem>
    <MenuItem value={'Scope 2'}>Scope 2</MenuItem>
    <MenuItem value={'Scope 3'}>Scope 3</MenuItem>
    <MenuItem value={'Outside of Scopes'}>Outside of Scopes</MenuItem>
  </Select>
)

ScopeFilter.propTypes = {
  filter: PropTypes.object,
  onChange: PropTypes.func,
}

const columns = [
  {
    accessor: 'Scope',
    Filter: ScopeFilter,
    filterAll: true,
    Header: 'Scope',
    sortMethod: (a, b) => Number(a) - Number(b),
    width: 100,
  },
  {
    accessor: 'Level_1',
    filterAll: true,
    Header: 'Level 1',
  },
  {
    accessor: 'Level_2',
    filterAll: true,
    Header: 'Level 2',
  },
  {
    accessor: 'Level_3',
    filterAll: true,
    Header: 'Level 3',
  },
  {
    accessor: 'Level_4',
    filterAll: true,
    Header: 'Level 4',
  },
  {
    accessor: 'Column_Text',
    filterAll: true,
    Header: 'Column Text',
  },
  {
    accessor: 'UOM_Simple',
    filterAll: true,
    Header: 'UOM Simple',
  },
  {
    accessor: 'UOM',
    filterAll: true,
    Header: 'UOM',
  },
  {
    accessor: 'GHG',
    filterAll: true,
    Header: 'GHG',
  },
  {
    accessor: 'Lookup',
    filterAll: true,
    Header: 'Lookup',
  },
  {
    accessor: 'GHG_CF_2020',
    filterAll: true,
    Header: 'GHG_CF_2020',
  },
]

export const Calculator = () => {
  // get data from strapi backend
  const { loading, error, data: meta } = useFetch('/metas')

  const history = useHistory()
  const classes = useStyles()

  const [activeStep, setActiveStep] = useState(0)
  const [tab, setTab] = useState(0)
  const [rows, setRows] = useState(50)

  let tableRef = useRef(null)

  const steps = [
    'Allgemeine Informationen',
    'Datenerfassung',
    'CO₂-Footprint',
    'Neutralstellung / Kompensation',
    'Angebot / Abschluss',
  ]

  const factors = {
    // ln 1932
    electricity: 0.23104,
    // ln 56
    naturalGas: 0.18352,
    // ln 128
    oilHeating: 2.52782,
    // ln 2244
    teleHeating: 0.17102,
    // ---
    // ln 160
    diesel: 2.65242,
    // ln 256
    benzine: 2.30176,
    // ln 4300
    publicTransport: 0.03659,
    // ln todo
    shortHaulFlight: 3,
    // ln todo
    mediumHaulFlight: 2,
    // ln todo
    longHaulFlight: 1,
    // ln 6881
    paper: 21.3,
    // ln 6687
    waste: 1.0,
    // ln todo
    supplier: 1.0,
    // ln todo
    customer: 1.0,
  }

  const [config, setConfig] = useState({
    // Tab 1 (Energiedaten)
    consumption: {
      electricity: 2500,
      isEco: true,
      naturalGas: 10000,
      oilHeating: 15,
      teleHeating: 25000,
    },
    // Tab 2 (Kraftstoffbezogene Emissionen)
    emissions: {
      diesel: 2500,
      benzine: 2500,
      publicTransport: 12500,
      shortHaulFlight: 3,
      mediumHaulFlight: 2,
      longHaulFlight: 1,
    },
    // Tab 3 (Material & Sonstiges)
    material: {
      paper: 100,
      waste: 200,
      supplier: 300,
      customer: 400,
    },
    // Tab 4 (Klima-Invest)
    climateInvest: 50,
    // compensation; should be one of the four
    compensationMethod: null,
  })

  const [jsonSelector, setJsonSelector] = useState({
    Scope: null,
    Level_1: null,
    Level_2: null,
    Level_3: null,
    Level_4: null,
    Column_Text: null,
    UOM_Simple: null,
    UOM: null,
    GHG: null,
    Lookup: null,
    GHG_CF_2020: null,
  })

  if (error) return <p>error ... {error}</p>

  const chartData = [
    ['Element', 'Wert', { role: 'style' }],
    ['Liegenschaften', 900, '#5DA5DD'],
    ['Transport', 100, '#5DA5DD'],
    ['Sonstiges', 20, '#5DA5DD'],
    ['Klima-Invest', 20, 'color: #5DA5DD'],
  ]

  const pieData = [
    ['Element', 'Wert', { role: 'style' }],
    ['Scope 1', 31.28, '#B84746'],
    ['Scope 2', 47.99, '#91B04D'],
    ['Scope 3', 20.72, '#538DCC'],
    ['RFI', 0.1, 'color: #FCCB05'],
  ]

  return loading ? (
    <div>loading ...</div>
  ) : (
    <Paper elevation={0}>
      {/*console.log(meta[0])*/}
      <div className={classes.root}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, key) => {
            const stepProps = {}
            const labelProps = {}

            return (
              <Step key={key} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <>
          <Container>
            <Breadcrumbs className={classes.locations}>
              <Link
                color={'inherit'}
                onClick={() => history.push('/calculator')}
              >
                <AddCircle className={classes.icon} />
                Neuer Standort
              </Link>
            </Breadcrumbs>

            <>
              {activeStep === 0 && (
                <Container>
                  <Typography component={'h4'} variant={'h4'}>
                    Allgemeine Informationen
                  </Typography>

                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      error={meta[0].company.length < 2}
                      label={'Firmenname'}
                      onChange={e => {
                        const { meta } = config
                        meta.company = e.target.value

                        setConfig({ ...config, meta })
                      }}
                      required
                      value={meta[0].company}
                      variant={'outlined'}
                    />
                  </FormControl>

                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      error={meta[0].contact.length < 2}
                      label={'Ansprechpartner'}
                      onChange={e => {
                        const { meta } = config
                        meta.contact = e.target.value

                        setConfig({ ...config, meta })
                      }}
                      required
                      value={meta[0].contact}
                      variant={'outlined'}
                    />
                  </FormControl>

                  <Container className={classes.footer}>
                    <Button
                      className={classes.button}
                      color={'primary'}
                      disabled={
                        meta[0].company.length < 2 || meta[0].contact.length < 2
                      }
                      onClick={() =>
                        setActiveStep(prevActiveStep => prevActiveStep + 1)
                      }
                      variant={'contained'}
                    >
                      CO₂ Footprint berechnen
                    </Button>
                  </Container>
                </Container>
              )}
              {activeStep === 1 && (
                <Container>
                  <Tabs
                    centered
                    indicatorColor={'primary'}
                    onChange={(e, tab) => setTab(tab)}
                    scrollButtons={'auto'}
                    textColor={'primary'}
                    value={tab}
                  >
                    <Tab value={0} label={'Energiedaten'} />
                    <Tab value={1} label={'Kraftstoffbezogene Emissionen'} />
                    <Tab value={2} label={'Material & Sonstiges'} />
                    <Tab value={3} label={'Klima-Invest'} />
                  </Tabs>
                  <>
                    {/* Energiedaten */}
                    {tab === 0 && (
                      <Grid container>
                        <Grid className={classes.grid} item xs={10}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              label={'Stromverbrauch'}
                              onChange={e => {
                                const { consumption } = config
                                consumption.electricity = e.target.value

                                setConfig({ ...config, consumption })
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    kWh
                                  </InputAdornment>
                                ),
                              }}
                              type={'number'}
                              value={config.consumption.electricity}
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={2}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              disabled
                              label={'CO₂-Äquivalent'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    t
                                  </InputAdornment>
                                ),
                              }}
                              type={'number'}
                              value={
                                (config.consumption.electricity *
                                  factors.electricity) /
                                1000
                              }
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={config.consumption.isEco}
                                color={'primary'}
                                onChange={() => {
                                  const { consumption } = config
                                  consumption.isEco = !consumption.isEco

                                  setConfig({ ...config, consumption })
                                }}
                              />
                            }
                            label={'Ökostrom'}
                          />
                        </Grid>

                        <Grid className={classes.grid} item xs={10}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              label={'Erdgas'}
                              onChange={e => {
                                const { consumption } = config
                                consumption.naturalGas = e.target.value

                                setConfig({ ...config, consumption })
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    kWh
                                  </InputAdornment>
                                ),
                              }}
                              type={'number'}
                              value={config.consumption.naturalGas}
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={2}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              disabled
                              label={'CO₂-Äquivalent'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    t
                                  </InputAdornment>
                                ),
                              }}
                              type={'number'}
                              value={
                                (config.consumption.naturalGas *
                                  factors.naturalGas) /
                                1000
                              }
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={10}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              label={'Heizöl'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    l
                                  </InputAdornment>
                                ),
                              }}
                              onChange={e => {
                                const { consumption } = config
                                consumption.oilHeating = e.target.value

                                setConfig({ ...config, consumption })
                              }}
                              type={'number'}
                              value={config.consumption.oilHeating}
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={2}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              disabled
                              label={'CO₂-Äquivalent'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    t
                                  </InputAdornment>
                                ),
                              }}
                              type={'number'}
                              value={
                                (config.consumption.oilHeating *
                                  factors.oilHeating) /
                                1000
                              }
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={10}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              label={'Fernwärme'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    kWh
                                  </InputAdornment>
                                ),
                              }}
                              onChange={e => {
                                const { consumption } = config
                                consumption.teleHeating = e.target.value

                                setConfig({ ...config, consumption })
                              }}
                              type={'number'}
                              value={config.consumption.teleHeating}
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={2}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              disabled
                              label={'CO₂-Äquivalent'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    t
                                  </InputAdornment>
                                ),
                              }}
                              type={'number'}
                              value={
                                (config.consumption.teleHeating *
                                  factors.teleHeating) /
                                1000
                              }
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                    {/* Kraftstoffbezogene Emissionen */}
                    {tab === 1 && (
                      <Grid container>
                        <Grid className={classes.grid} item xs={10}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              helperText={'Infofeld: lorem ipsum ...'}
                              label={'Diesel'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    l
                                  </InputAdornment>
                                ),
                              }}
                              onChange={e => {
                                const { emissions } = config
                                emissions.diesel = e.target.value

                                setConfig({ ...config, emissions })
                              }}
                              type={'number'}
                              value={config.emissions.diesel}
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={2}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              disabled
                              label={'CO₂-Äquivalent'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    t
                                  </InputAdornment>
                                ),
                              }}
                              type={'number'}
                              value={
                                (config.emissions.diesel * factors.diesel) /
                                1000
                              }
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={10}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              helperText={'Infofeld: lorem ipsum ...'}
                              label={'Benzin'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    l
                                  </InputAdornment>
                                ),
                              }}
                              onChange={e => {
                                const { emissions } = config
                                emissions.benzine = e.target.value

                                setConfig({ ...config, emissions })
                              }}
                              type={'number'}
                              value={config.emissions.benzine}
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={2}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              disabled
                              label={'CO₂-Äquivalent'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    t
                                  </InputAdornment>
                                ),
                              }}
                              type={'number'}
                              value={
                                (config.emissions.benzine * factors.benzine) /
                                1000
                              }
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={10}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              label={'Bahn / ÖPNV'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    km
                                  </InputAdornment>
                                ),
                              }}
                              onChange={e => {
                                const { emissions } = config
                                emissions.publicTransport = e.target.value

                                setConfig({ ...config, emissions })
                              }}
                              type={'number'}
                              value={config.emissions.publicTransport}
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={2}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              disabled
                              label={'CO₂-Äquivalent'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    t
                                  </InputAdornment>
                                ),
                              }}
                              type={'number'}
                              value={
                                (config.emissions.publicTransport *
                                  factors.publicTransport) /
                                1000
                              }
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={10}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              label={'Kurzstreckenflüge (< 3h)'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    Anzahl
                                  </InputAdornment>
                                ),
                              }}
                              onChange={e => {
                                const { emissions } = config
                                emissions.shortHaulFlight = e.target.value

                                setConfig({ ...config, emissions })
                              }}
                              type={'number'}
                              value={config.emissions.shortHaulFlight}
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={2}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              disabled
                              label={'CO₂-Äquivalent'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    t
                                  </InputAdornment>
                                ),
                              }}
                              type={'number'}
                              value={
                                (config.emissions.shortHaulFlight *
                                  factors.shortHaulFlight) /
                                1000
                              }
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={10}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              label={'Mittelstreckenflüge (3-6h)'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    Anzahl
                                  </InputAdornment>
                                ),
                              }}
                              onChange={e => {
                                const { emissions } = config
                                emissions.mediumHaulFlight = e.target.value

                                setConfig({ ...config, emissions })
                              }}
                              type={'number'}
                              value={config.emissions.mediumHaulFlight}
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={2}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              disabled
                              label={'CO₂-Äquivalent'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    t
                                  </InputAdornment>
                                ),
                              }}
                              type={'number'}
                              value={
                                (config.emissions.mediumHaulFlight *
                                  factors.mediumHaulFlight) /
                                1000
                              }
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={10}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              label={'Langstreckenflüge (> 6h)'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    Anzahl
                                  </InputAdornment>
                                ),
                              }}
                              onChange={e => {
                                const { emissions } = config
                                emissions.longHaulFlight = e.target.value

                                setConfig({ ...config, emissions })
                              }}
                              type={'number'}
                              value={config.emissions.longHaulFlight}
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={2}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              disabled
                              label={'CO₂-Äquivalent'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    t
                                  </InputAdornment>
                                ),
                              }}
                              type={'number'}
                              value={
                                (config.emissions.longHaulFlight *
                                  factors.longHaulFlight) /
                                1000
                              }
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                    {/* Material & Sonstiges */}
                    {tab === 2 && (
                      <Grid container>
                        <Grid className={classes.grid} item xs={10}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              label={'Papier'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    kg
                                  </InputAdornment>
                                ),
                              }}
                              onChange={e => {
                                const { material } = config
                                material.paper = e.target.value

                                setConfig({ ...config, material })
                              }}
                              type={'number'}
                              value={config.material.paper}
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={2}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              disabled
                              label={'CO₂-Äquivalent'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    t
                                  </InputAdornment>
                                ),
                              }}
                              type={'number'}
                              value={
                                ((config.material.paper / 1000) *
                                  factors.paper) /
                                1000
                              }
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={10}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              label={'Abfall'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    kg
                                  </InputAdornment>
                                ),
                              }}
                              onChange={e => {
                                const { material } = config
                                material.waste = e.target.value

                                setConfig({ ...config, material })
                              }}
                              type={'number'}
                              value={config.material.waste}
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={2}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              disabled
                              label={'CO₂-Äquivalent'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    t
                                  </InputAdornment>
                                ),
                              }}
                              type={'number'}
                              value={
                                ((config.material.waste / 1000) *
                                  factors.waste) /
                                1000
                              }
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={10}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              helperText={
                                'Emissionen aus Transport und Verteilung von Waren von Zulieferern'
                              }
                              label={'Vorgelagerte Aktivitäten'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    in Tonnen CO₂
                                  </InputAdornment>
                                ),
                              }}
                              onChange={e => {
                                const { material } = config
                                material.supplier = e.target.value

                                setConfig({ ...config, material })
                              }}
                              multiline
                              rows={5}
                              value={config.material.supplier}
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={2}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              disabled
                              label={'CO₂-Äquivalent'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    t
                                  </InputAdornment>
                                ),
                              }}
                              multiline
                              rows={5}
                              type={'number'}
                              value={
                                (config.material.supplier * factors.supplier) /
                                1000
                              }
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={10}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              helperText={
                                'Emissionen aus Transport und Verteilung von Waren an Abnehmer'
                              }
                              label={'Nachgelagerte Aktivitäten'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    in Tonnen CO₂
                                  </InputAdornment>
                                ),
                              }}
                              onChange={e => {
                                const { material } = config
                                material.customer = e.target.value

                                setConfig({ ...config, material })
                              }}
                              multiline
                              rows={5}
                              value={config.material.customer}
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>

                        <Grid className={classes.grid} item xs={2}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              disabled
                              label={'CO₂-Äquivalent'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    t
                                  </InputAdornment>
                                ),
                              }}
                              multiline
                              rows={5}
                              type={'number'}
                              value={
                                (config.material.customer * factors.customer) /
                                1000
                              }
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                    {/* Klima-Invest */}
                    {tab === 3 && (
                      <Grid container>
                        <Grid className={classes.grid} item xs={12}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <TextField
                              label={
                                'Bereits kompensierte Menge an CO₂-Emissionen aus bestehenden Klimaschutzzertifikaten oder -projekten'
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>
                                    in Tonnen CO₂
                                  </InputAdornment>
                                ),
                              }}
                              onChange={e => {
                                const climateInvest = e.target.value

                                setConfig({
                                  ...config,
                                  climateInvest,
                                })
                              }}
                              multiline
                              rows={5}
                              value={config.climateInvest}
                              variant={'outlined'}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}{' '}
                    <Alert severity={'info'}>
                      Unausgefüllte Felder werden nicht in die Kalkulation
                      miteinbezogen
                    </Alert>
                  </>

                  <Container className={classes.footer}>
                    <Button
                      className={classes.button}
                      onClick={() => {
                        if (tab === 0)
                          setActiveStep(prevActiveStep => prevActiveStep - 1)
                        else setTab(prevActiveTab => prevActiveTab - 1)
                      }}
                      variant={'contained'}
                    >
                      Zurück
                    </Button>

                    <Button
                      className={classes.button}
                      color={'primary'}
                      onClick={() => {
                        if (tab === 3)
                          setActiveStep(prevActiveStep => prevActiveStep + 1)
                        else setTab(prevActiveTab => prevActiveTab + 1)
                      }}
                      variant={'contained'}
                    >
                      {tab !== 3 && 'Weiter'}
                      {tab === 3 && 'C02 Footprint berechnen'}
                    </Button>
                  </Container>
                </Container>
              )}
              {activeStep === 2 && (
                <Container>
                  <Typography
                    className={classes.header}
                    component={'h4'}
                    variant={'h4'}
                  >
                    CO₂-Footprint
                  </Typography>

                  <Typography component={'h6'} variant={'h6'}>
                    Ergebnis
                  </Typography>

                  <Typography
                    className={classes.section}
                    component={'h6'}
                    variant={'h6'}
                  >
                    Die in diesem Projekt erfassten Treibhausgas relevanten
                    Prozesse führen zu einem <i>Carbon Footprint</i> von 981.38
                    t CO₂ e.
                  </Typography>

                  <TableContainer component={Paper} elevation={0}>
                    <Table className={classes.table}>
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.bold}>
                            Projektname
                          </TableCell>
                          <TableCell className={classes.bold} align={'right'}>
                            {meta[0].company}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className={classes.bold}>
                            Bezugsjahr
                          </TableCell>
                          <TableCell className={classes.bold} align={'right'}>
                            2021
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className={classes.bold}>
                            Berechnungsstandard
                          </TableCell>
                          <TableCell className={classes.bold} align={'right'}>
                            vertraglich
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className={classes.bold}>Region</TableCell>
                          <TableCell className={classes.bold} align={'right'}>
                            Deutschland
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className={classes.bold}>Profil</TableCell>
                          <TableCell className={classes.bold} align={'right'}>
                            WM Demoversion
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Erstellungsdatum</TableCell>
                          <TableCell align={'right'}>
                            {new Date(Date.now()).toISOString()}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Typography component={'h6'} variant={'h6'}>
                    Ergebnis der einzelnen Sektoren
                  </Typography>

                  <TableContainer component={Paper} elevation={0}>
                    <Table className={classes.table}>
                      <TableBody>
                        <TableRow className={classes.section}>
                          <TableCell>Sektor</TableCell>
                          <TableCell align={'right'}>CO2e [t]</TableCell>
                          <TableCell align={'right'}>CO2e [%]</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className={classes.bold}>
                            Energiedaten
                          </TableCell>
                          <TableCell className={classes.bold} align={'right'}>
                            898.85
                          </TableCell>
                          <TableCell className={classes.bold} align={'right'}>
                            91.59
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>&gt;Wärme</TableCell>
                          <TableCell align={'right'}>339.15</TableCell>
                          <TableCell align={'right'}>34.56</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>&gt;Strom</TableCell>
                          <TableCell align={'right'}>559.70</TableCell>
                          <TableCell align={'right'}>57.03</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className={classes.bold}>
                            Kraftstoffbezogene Emissionen
                          </TableCell>
                          <TableCell className={classes.bold} align={'right'}>
                            75.88
                          </TableCell>
                          <TableCell className={classes.bold} align={'right'}>
                            7.73
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>&gt;Fuhrpark</TableCell>
                          <TableCell align={'right'}>41.12</TableCell>
                          <TableCell align={'right'}>4.19</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>&gt;Geschäftsfahrten</TableCell>
                          <TableCell align={'right'}>34.76</TableCell>
                          <TableCell align={'right'}>3.54</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className={classes.bold}>
                            Material &amp; Sonstiges
                          </TableCell>
                          <TableCell className={classes.bold} align={'right'}>
                            6.65
                          </TableCell>
                          <TableCell className={classes.bold} align={'right'}>
                            0.68
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>&gt;Papier</TableCell>
                          <TableCell align={'right'}>
                            {((config.material.paper / 1000) * factors.paper) /
                              1000}
                          </TableCell>
                          <TableCell align={'right'}>0.68</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Typography component={'h6'} variant={'h6'}>
                    Ergebnis nach Geltungsbereich
                  </Typography>

                  <TableContainer component={Paper} elevation={0}>
                    <Table className={classes.table}>
                      <TableBody>
                        <TableRow className={classes.section}>
                          <TableCell>Ergebnis</TableCell>
                          <TableCell align={'right'}>CO2e [t]</TableCell>
                          <TableCell align={'right'}>CO2e [%]</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Direkte Emissionen (Scope 1)</TableCell>
                          <TableCell align={'right'}>307.02</TableCell>
                          <TableCell align={'right'}>31.28</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            Indirekte Emissionen aus bereitgestellter Energie
                            (Scope 2)
                          </TableCell>
                          <TableCell align={'right'}>471.00</TableCell>
                          <TableCell align={'right'}>47.99</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            Weitere indirekte Emissionen (Scope 3)
                          </TableCell>
                          <TableCell align={'right'}>203.37</TableCell>
                          <TableCell align={'right'}>20.72</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className={classes.bold}>
                            Gesamte Emissionen
                          </TableCell>
                          <TableCell className={classes.bold} align={'right'}>
                            981.38
                          </TableCell>
                          <TableCell className={classes.bold} align={'right'}>
                            100.00
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            Zusätzliche Treibhausgaswirkung durch Flugreisen
                          </TableCell>
                          <TableCell align={'right'}>0.00</TableCell>
                          <TableCell align={'right'}>-</TableCell>
                        </TableRow>

                        <TableRow className={classes.section}>
                          <TableCell>Sektor</TableCell>
                          <TableCell align={'right'}>CO2e [t]</TableCell>
                          <TableCell align={'right'}>CO2e [%]</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className={classes.bold}>
                            Klima-Invest
                          </TableCell>
                          <TableCell
                            className={classes.bold}
                            colSpan={2}
                            align={'right'}
                          >
                            ---
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <FormControl className={classes.header} fullWidth>
                    <Typography component={'h4'} variant={'h4'}>
                      Verteilung der CO₂-Emissionen nach Kategorie
                    </Typography>
                  </FormControl>

                  <Chart
                    chartType={'ColumnChart'}
                    data={chartData}
                    height={'300px'}
                    loader={<>bitte warten...</>}
                    options={{ legend: 'none' }}
                  />

                  <Chart
                    chartType={'PieChart'}
                    data={pieData}
                    height={'300px'}
                    loader={<>bitte warten...</>}
                    options={{ is3D: true }}
                  />

                  <Container className={classes.footer}>
                    <Button color={'primary'} disabled variant={'contained'}>
                      Download als PDF
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() =>
                        setActiveStep(prevActiveStep => prevActiveStep - 1)
                      }
                      variant={'contained'}
                    >
                      Zurück
                    </Button>

                    <Button
                      variant={'contained'}
                      color={'primary'}
                      onClick={() => {
                        if (tab === 3)
                          setActiveStep(prevActiveStep => prevActiveStep + 1)
                        else setTab(prevActiveTab => prevActiveTab + 1)
                      }}
                      className={classes.button}
                    >
                      Jetzt kompensieren
                    </Button>
                  </Container>
                </Container>
              )}
              {activeStep === 3 && (
                <Container>
                  <Grid container>
                    <Grid item xs={3}>
                      <Card
                        className={classes.card}
                        onClick={() =>
                          setConfig({ ...config, compensationMethod: 1 })
                        }
                      >
                        <CardActionArea>
                          <CardMedia
                            component={'img'}
                            height={150}
                            image={COMPENSATION_0}
                            title={''}
                          />

                          <CardContent>
                            <Typography>teaser text günstig</Typography>
                            <Button color={'primary'} variant={'outlined'}>
                              5 € je Tonne
                            </Button>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>

                    <Grid item xs={3}>
                      <Card
                        className={classes.card}
                        onClick={() =>
                          setConfig({ ...config, compensationMethod: 2 })
                        }
                      >
                        <CardActionArea>
                          <CardMedia
                            component={'img'}
                            height={150}
                            image={COMPENSATION_1}
                            title={''}
                          />
                          <CardContent>
                            <Typography>teaser text mittel</Typography>
                            <Button color={'primary'} variant={'outlined'}>
                              10 € je Tonne
                            </Button>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>

                    <Grid item xs={3}>
                      <Card
                        className={classes.card}
                        onClick={() =>
                          setConfig({ ...config, compensationMethod: 3 })
                        }
                      >
                        <CardActionArea>
                          <CardMedia
                            component={'img'}
                            height={150}
                            image={COMPENSATION_2}
                            title={''}
                          />
                          <CardContent>
                            <Typography>teaser text hochwertig</Typography>
                            <Button color={'primary'} variant={'outlined'}>
                              20 € je Tonne
                            </Button>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>

                    <Grid item xs={3}>
                      <Card
                        className={classes.card}
                        onClick={() =>
                          setConfig({ ...config, compensationMethod: 4 })
                        }
                      >
                        <CardActionArea>
                          <CardMedia
                            component={'img'}
                            height={150}
                            image={COMPENSATION_3}
                            title={''}
                          />
                          <CardContent>
                            <Typography>
                              teaser text CO₂-Neutralstellung mit
                              TÜV-Zertifizierung
                            </Typography>
                            <a
                              href={'https://karma.de'}
                              rel={'noreferrer'}
                              target={'_blank'}
                            >
                              Warum wichtig?
                            </a>
                            <br />
                            <br />
                            <Button color={'primary'} variant={'outlined'}>
                              Mehr erfahren
                            </Button>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </Grid>

                  <Container className={classes.footer}>
                    <Button
                      className={classes.button}
                      onClick={() =>
                        setActiveStep(prevActiveStep => prevActiveStep - 1)
                      }
                      variant={'contained'}
                    >
                      Zurück
                    </Button>

                    <Button
                      disabled={!config.compensationMethod}
                      variant={'contained'}
                      color={'primary'}
                      onClick={() =>
                        setActiveStep(prevActiveStep => prevActiveStep + 1)
                      }
                      className={classes.button}
                    >
                      {config.compensationMethod === 4 ? 'Beratung' : 'Angebot'}{' '}
                      anfordern
                    </Button>
                  </Container>
                </Container>
              )}
              {activeStep === 4 && (
                <Container>
                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      error={meta[0].company.length < 2}
                      label={'Unternehmen'}
                      onChange={e => {
                        const { meta } = config
                        meta.company = e.target.value

                        setConfig({ ...config, meta })
                      }}
                      required
                      type={'text'}
                      value={meta[0].company}
                      variant={'outlined'}
                    />
                  </FormControl>

                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      error={
                        !meta[0].zip ||
                        (meta[0].zip &&
                          (meta[0].zip < 1001 || meta[0].zip > 99998))
                      }
                      onChange={e => {
                        const { meta } = config
                        meta.zip = e.target.value

                        setConfig({ ...config, meta })
                      }}
                      label={'Postleitzahl'}
                      required
                      type={'number'}
                      value={meta[0].zip}
                      variant={'outlined'}
                    />
                  </FormControl>

                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      error={!meta[0].city || meta[0].city.length < 2}
                      label={'Ort'}
                      onChange={e => {
                        const { meta } = config
                        meta.city = e.target.value

                        setConfig({ ...config, meta })
                      }}
                      required
                      type={'text'}
                      value={meta[0].city}
                      variant={'outlined'}
                    />
                  </FormControl>

                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      error={!meta[0].street || meta[0].street.length < 2}
                      label={'Straße'}
                      onChange={e => {
                        const { meta } = config
                        meta.street = e.target.value

                        setConfig({ ...config, meta })
                      }}
                      required
                      type={'text'}
                      value={meta[0].street}
                      variant={'outlined'}
                    />
                  </FormControl>

                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      error={
                        !meta[0].housenumber || meta[0].housenumber.length < 1
                      }
                      label={'Hausnummer'}
                      onChange={e => {
                        const { meta } = config
                        meta.housenumber = e.target.value

                        setConfig({ ...config, meta })
                      }}
                      required
                      type={'text'}
                      value={meta[0].housenumber}
                      variant={'outlined'}
                    />
                  </FormControl>

                  <FormControl
                    className={classes.formControl}
                    fullWidth
                    variant={'outlined'}
                  >
                    <InputLabel>Anrede</InputLabel>
                    <Select
                      autoWidth
                      label={'Anrede'}
                      onChange={e => {
                        const { meta } = config
                        meta.title = e.target.value

                        setConfig({ ...config, meta })
                      }}
                      required
                      type={'text'}
                      value={meta[0].title}
                      variant={'outlined'}
                    >
                      <MenuItem value={'Frau'}>Frau</MenuItem>
                      <MenuItem value={'Herr'}>Herr</MenuItem>
                      <MenuItem value={'Divers'}>Divers</MenuItem>
                    </Select>
                    <Link
                      href={
                        'https://www.onlinehaendler-news.de/e-recht/rechtsfragen/32701-wir-wurden-gefragt-dritte-geschlecht-bestellformularen'
                      }
                    >
                      Hinweis für die Praxis - Geschlecht weglassen
                    </Link>
                  </FormControl>

                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      error={!meta[0].forename || meta[0].forename.length < 2}
                      label={'Vorname'}
                      onChange={e => {
                        const { meta } = config
                        meta.forename = e.target.value

                        setConfig({ ...config, meta })
                      }}
                      required
                      type={'text'}
                      value={meta[0].forename}
                      variant={'outlined'}
                    />
                  </FormControl>

                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      error={!meta[0].surname || meta[0].surname.length < 2}
                      label={'Nachname'}
                      onChange={e => {
                        const { meta } = config
                        meta.surname = e.target.value

                        setConfig({ ...config, meta })
                      }}
                      required
                      type={'text'}
                      value={meta[0].surname}
                      variant={'outlined'}
                    />
                  </FormControl>

                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      error={!meta[0].telephone || meta[0].telephone.length < 3}
                      label={'Telefon'}
                      onChange={e => {
                        const { meta } = config
                        meta.telephone = e.target.value

                        setConfig({ ...config, meta })
                      }}
                      required
                      type={'tel'}
                      value={meta[0].telephone}
                      variant={'outlined'}
                    />
                  </FormControl>

                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      error={!meta[0].email || meta[0].email.length < 3}
                      label={'E-Mail'}
                      onChange={e => {
                        const { meta } = config
                        meta.email = e.target.value

                        setConfig({ ...config, meta })
                      }}
                      required
                      type={'email'}
                      value={meta[0].email}
                      variant={'outlined'}
                    />
                  </FormControl>

                  <Container className={classes.footer}>
                    <Button
                      className={classes.button}
                      onClick={() =>
                        setActiveStep(prevActiveStep => prevActiveStep - 1)
                      }
                      variant={'contained'}
                    >
                      Zurück
                    </Button>

                    <Button
                      variant={'contained'}
                      color={'primary'}
                      onClick={() =>
                        setActiveStep(prevActiveStep => prevActiveStep + 1)
                      }
                      className={classes.button}
                    >
                      {config.compensationMethod === 4 ? 'Beratung' : 'Angebot'}{' '}
                      anfordern
                    </Button>
                  </Container>
                </Container>
              )}
              {activeStep === 5 && (
                <Container>
                  {Object.keys(jsonData[0]).map((item, key) => {
                    return (
                      <FormControl fullWidth key={key} variant={'outlined'}>
                        <InputLabel>{item}</InputLabel>
                        <Select
                          autoWidth
                          label={item}
                          onChange={e => {
                            setJsonSelector({
                              ...jsonSelector,
                              [item]: e.target.value,
                            })
                          }}
                        >
                          <MenuItem
                            disabled={jsonSelector[item] === null}
                            value={null}
                          >
                            {item} wählen ...
                          </MenuItem>
                          {[...new Set(jsonData.map(item2 => item2[item]))].map(
                            (i, key) => (
                              <MenuItem key={key} value={i}>
                                {i}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    )
                  })}

                  <Typography>raw JSON</Typography>

                  {/*console.log(jsonData)*/}
                  {/*console.log(jsonSelector)*/}
                  {/*<ReactJson
                    _collapsed={'true'}
                    src={jsonData
                      .filter(
                        (f) =>
                          f.Scope ===
                          (jsonSelector.Scope === null
                            ? f.Scope
                            : jsonSelector.Scope)
                      )
                      .filter(
                        (f) =>
                          f.Level_1 ===
                          (jsonSelector.Level_1 === null
                            ? f.Level_1
                            : jsonSelector.Level_1)
                      )
                      .filter(
                        (f) =>
                          f.Level_2 ===
                          (jsonSelector.Level_2 === null
                            ? f.Level_2
                            : jsonSelector.Level_2)
                      )
                      .filter(
                        (f) =>
                          f.Level_3 ===
                          (jsonSelector.Level_3 === null
                            ? f.Level_3
                            : jsonSelector.Level_3)
                      )
                      .filter(
                        (f) =>
                          f.Level_4 ===
                          (jsonSelector.Level_4 === null
                            ? f.Level_4
                            : jsonSelector.Level_4)
                      )}
                  />*/}

                  <ReactTable
                    className={'-highlight'}
                    columns={columns}
                    data={jsonData}
                    defaultFilterMethod={(filter, row) =>
                      matchSorter(row, filter.value, { keys: [filter.id] })
                    }
                    filterable
                    NoDataComponent={() => null}
                    onFilteredChange={() => {
                      const { length } = tableRef.getResolvedState().sortedData

                      if (length) setRows(length > 50 ? 50 : length)
                    }}
                    pageSize={rows}
                    ref={ref => (tableRef = ref)}
                    showPaginationBottom={false}
                  />

                  <Container className={classes.footer}>
                    <Button
                      className={classes.button}
                      onClick={() =>
                        setActiveStep(prevActiveStep => prevActiveStep - 1)
                      }
                      variant={'contained'}
                    >
                      Zurück
                    </Button>
                  </Container>
                </Container>
              )}
            </>
          </Container>
        </>
      </div>
    </Paper>
  )
}
