import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
//
import PropTypes from 'prop-types'
import React, { useState } from 'react'
//
import { ICO_ARROW_DOWN } from '../../img'

const useStyles = makeStyles(() => ({
  root: {
    padding: '0px 0',
    background: '#FFF',
    position: 'relative',
    paddingBottom: '50px',
  },
  container: {
    width: '100%',
    maxWidth: '1194px',
  },
  container2: {
    width: '100%',
    maxWidth: '500px',
    textAlign: 'right',
  },
  caption: {
    font: '40px RobotoSlab !important',
    paddingBottom: '50px',
  },
  description: {
    borderBottom: 'solid 2px #FFFFFF',
    paddingBottom: '50px',
    textAlign: 'justify',
  },
  accordion: {
    borderTop: 'solid 1px #707070 !important',
    borderBottom: 'solid 1px #707070',
  },
  accordionSummary: {
    font: '40px Roboto !important',
    paddingBottom: '20px !important',
    paddingTop: '20px !important',
  },
  details: {
    font: '20px Roboto !important',
    maxWidth: '745px !important',
    textAlign: 'justify',
  },
  summary: {
    marginTop: '50px !important',
    font: '20px Roboto !important',
    maxWidth: '745px !important',
    width: '100%',
    textAlign: 'justify',
  },
}))

export const Efficiency = props => {
  const classes = useStyles()
  const { data } = props
  const { items, title, description, summary } = data

  const [active, setActive] = useState(null)

  return data ? (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Typography className={classes.caption}>{title}</Typography>
        <Typography className={classes.description}>{description}</Typography>

        <div className={classes.accordion}>
          {items.map((item, key) => {
            const { description, title } = item

            return (
              <Accordion
                disableGutters
                elevation={0}
                expanded={active === key}
                key={key}
                onChange={() => setActive(key)}
                style={{ backgroundColor: 'transparent' }}
                sx={{
                  '&.Mui-expanded': {
                    minHeight: 0,
                    margin: '12px 0',
                    borderTop: 'solid 1px #707070',
                  },
                  '& .MuiAccordionSummary-content.Mui-expanded': {
                    margin: 0,
                  },
                }}
              >
                <AccordionSummary
                  className={classes.accordionSummary}
                  expandIcon={<img src={ICO_ARROW_DOWN} alt={''} />}
                >
                  {title}
                </AccordionSummary>

                <AccordionDetails className={classes.details}>
                  {description}
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>
      </Container>

      <Container className={classes.container2}>
        <Typography className={classes.summary}>{summary}</Typography>
      </Container>
    </div>
  ) : (
    ''
  )
}

Efficiency.propTypes = {
  data: PropTypes.object,
  icon: PropTypes.string,
}
