import { Collapse, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useContext } from 'react'
//
import {
  Contact,
  Efficiency,
  EnergyAudit,
  Footer,
  Newsletter,
  SingleText,
} from './components'
import GrantsContext from '../context/grants.context'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#FFF !important',
    margin: '0 auto',
    width: '100%',
    minWidth: '800px',
    maxWidth: '1920px',
    maxHeight: '1080px',
  },
}))

export const Grants = () => {
  const classes = useStyles()
  const data = useContext(GrantsContext)
  const { contact, efficiency, energyAudit, text } = data

  return (
    <Paper className={classes.root} elevation={0}>
      {/* SingleText */}
      {text && (
        <Collapse in={true}>
          <SingleText data={text} />
        </Collapse>
      )}

      {/* Efficiency */}
      {efficiency && (
        <Collapse in={true}>
          <Efficiency data={efficiency} />
        </Collapse>
      )}

      {/* EnergyAudit */}
      {energyAudit && (
        <Collapse in={true} style={{ background: '#FFF' }}>
          <EnergyAudit data={energyAudit} />
        </Collapse>
      )}

      {/* Contact */}
      {contact && (
        <Collapse in={true}>
          <Contact data={contact} />
        </Collapse>
      )}

      {/* Newsletter */}
      <Newsletter />

      {/* Footer */}
      <Footer data={data.footer} />
    </Paper>
  )
}
