import {
  Card,
  CardContent,
  Collapse,
  Container,
  ImageList,
  ImageListItem,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { ICO_WHITE_ARROW_RIGHT } from './../../img'

const useStyles = makeStyles(() => ({
  root: {
    background: '#FFF',
  },
  title: {
    font: '40px RobotoSlab !important',
    maxWidth: '765px',
    marginBottom: '50px !important',
  },
  tile: {
    backgroundColor: '#E6E6E6',
    padding: '80px',
    color: '#A00523',
    cursor: 'pointer',
    font: '40px RobotoSlab !important',
    position: 'absolute',
    bottom: '0px',
  },
  tileActive: {
    backgroundColor: '#A00523',
    padding: '80px',
    color: '#FFF',
    cursor: 'pointer',
    font: '40px RobotoSlab !important',
    position: 'absolute',
    bottom: '0px',
  },
  content: {
    color: 'white',
    font: '40px RobotoSlab !important',
    position: 'absolute',
    bottom: '0px',
    left: '30px',
    height: '280px',
  },
  description: {
    font: '20px Roboto !important',
    marginBottom: '20px !important',
    marginLeft: '50px !important',
    marginRight: '50px !important',
    textAlign: 'justify',
    hyphens: 'auto',
    width: '320px !important',
    display: 'inline-block',
  },
  descriptionSingle: {
    font: '20px Roboto !important',
    marginBottom: '20px !important',
    marginLeft: '50px !important',
    marginRight: '50px !important',
    textAlign: 'justify',
    hyphens: 'auto',
    width: '400px !important',
    display: 'inline-block',
  },
  itemDescription: { font: '20px Roboto !important' },
  source: {
    font: '14px Roboto !important',
    color: '#1D1D1B',
    marginTop: '50px !important',
  },
  big: {
    font: '120px RobotoSlab !important',
  },
}))

export const EnergyAudit = props => {
  const classes = useStyles()
  const { data } = props
  const { items, source, title } = data

  const [active, setActive] = useState(0)

  return data ? (
    <div className={classes.root}>
      <Container>
        <Typography className={classes.title}>{title}</Typography>
      </Container>

      <ImageList
        xl={{ width: 1920, height: 280 }}
        cols={2}
        rowHeight={280}
        style={{ margin: 0 }}
      >
        {items.map((item, key) => {
          const { description, summary, title } = item

          return (
            <ImageListItem
              key={key}
              className={key === active ? classes.tileActive : classes.tile}
              onClick={() => {
                setActive(key === active ? null : key)
              }}
            >
              <Card style={{ position: 'relative' }}>
                <CardContent className={classes.content}>
                  {title}
                  <br />
                  <img alt={''} src={ICO_WHITE_ARROW_RIGHT} />
                  <Collapse
                    in={key === active}
                    className={classes.itemDescription}
                  >
                    {description}
                  </Collapse>
                </CardContent>
              </Card>

              <Typography className={classes.title}>
                {title}
                <br />
                <img
                  alt={''}
                  src={ICO_WHITE_ARROW_RIGHT}
                  width={30}
                  height={30}
                />
              </Typography>
              <Collapse in={active === key} className={classes.itemDescription}>
                <Typography className={classes.summary}>{summary}</Typography>
              </Collapse>
            </ImageListItem>
          )
        })}
      </ImageList>

      <Container maxWidth={'xl'}>
        {active === 0 && (
          <Collapse in={true} style={{ marginTop: 50 }}>
            <div>
              <Typography className={classes.big} component={'span'}>
                1
              </Typography>
              <Typography
                className={classes.descriptionSingle}
                component={'span'}
              >
                {items[0].description}
              </Typography>
            </div>
          </Collapse>
        )}
        {active === 1 && (
          <Collapse in={true} style={{ marginTop: 50 }}>
            <span>
              <Typography className={classes.big} component={'span'}>
                1
              </Typography>
              <Typography className={classes.description}>
                {items[1].description}
              </Typography>
            </span>
            <span>
              <Typography className={classes.big} component={'span'}>
                2
              </Typography>
              <Typography className={classes.description} component={'span'}>
                {items[1].description2}
              </Typography>
            </span>
            <span>
              <Typography className={classes.big} component={'span'}>
                3
              </Typography>
              <Typography className={classes.description} component={'span'}>
                {items[1].description3}
              </Typography>
            </span>
          </Collapse>
        )}
      </Container>

      {active !== null && (
        <Container maxWidth={'xl'}>
          <Typography className={classes.source}>{source}</Typography>
        </Container>
      )}
    </div>
  ) : (
    ''
  )
}

EnergyAudit.propTypes = {
  data: PropTypes.object,
}
