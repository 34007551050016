import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { ICO_CHECKBOX } from '../../img'
import { useOnScreen } from '../../hooks/useOnScreen'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '150px 0',
    background: '#FFF',
    position: 'relative',
  },
  caption: {
    font: '60px RobotoSlab !important',
    marginBottom: '50px !important',
  },
  title: {
    marginBottom: '80px !important',
  },
  submit: {
    marginTop: '80px !important',
    backgroundColor: '#A00523 !important',
    border: 'none',
    color: 'white !important',
    width: '320px',
  },
  hotline: {
    marginTop: '200px !important',
    color: '#A00523',
    width: '100%',
    textAlign: 'center',
    font: '20px RobotoBlack !important',
  },
  hotlineTel: {
    font: '60px RobotoSlab !important',
    width: '100%',
    textAlign: 'center',
  },
  scaleQuote: {
    position: 'absolute',
    right: '200px',
    marginTop: '-80px',
    zIndex: '0',
    transform: 'scale(0.8)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
  },
  scaleQuoteDown: {
    position: 'absolute',
    right: '200px',
    marginTop: '-80px',
    zIndex: '0',
    transform: 'scale(1)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
  },
}))

export const IndividualRequest = props => {
  const { icon } = props
  const classes = useStyles()

  const [name, setName] = useState('')
  const [company, setCompany] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [privacyAck, setPrivacyAck] = useState(false)

  const ref = useRef()
  const isVisible = useOnScreen(ref)

  return (
    <div className={classes.root}>
      {/* render icon if provided */}
      {icon && (
        <img
          alt={''}
          className={isVisible ? classes.scaleQuoteDown : classes.scaleQuote}
          src={icon}
        />
      )}

      <Container maxWidth={'md'}>
        <Typography className={classes.caption}>
          Individuelle Anfrage
        </Typography>

        <FormControl fullWidth>
          <TextField
            className={classes.title}
            label={'Vor- und Nachname'}
            onChange={e => setName(e.target.value)}
            required
            type={'text'}
            value={name}
            variant={'standard'}
          />
        </FormControl>

        <FormControl fullWidth>
          <TextField
            className={classes.title}
            label={'Firma'}
            onChange={e => setCompany(e.target.value)}
            required
            type={'text'}
            value={company}
            variant={'standard'}
          />
        </FormControl>

        <FormControl fullWidth ref={ref}>
          <TextField
            className={classes.title}
            label={'Telefon'}
            onChange={e => setPhone(e.target.value)}
            required
            type={'tel'}
            value={phone}
            variant={'standard'}
          />
        </FormControl>

        <FormControl fullWidth>
          <TextField
            className={classes.title}
            label={'E-Mail'}
            onChange={e => setEmail(e.target.value)}
            required
            type={'email'}
            value={email}
            variant={'standard'}
          />
        </FormControl>

        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                checkedIcon={<CheckCircleIcon />}
                icon={<img alt={''} src={ICO_CHECKBOX} />}
                checked={privacyAck}
                onClick={() => setPrivacyAck(!privacyAck)}
              />
            }
            label={'Ich akzeptiere die Datenschutzbestimmungen des Anbieters'}
          />
          <div style={{ textAlign: 'center' }}>
            <Button
              className={classes.submit}
              disabled={!privacyAck}
              onClick={() =>
                (window.location = `mailto:info@wb-energie.de?subject=Einsparpotenzial&body=Sehr geehrte Damen und Herren, %0d %0dich möchte mehr über die Einsparpotenziale im Bereich Energie erfahren. %0dBitte kontaktieren Sie mich unter folgenden Kontaktdaten: ( Telefonnummer: ${phone}, E-Mail-Adresse: ${email})`)
              }
            >
              Informieren Sie mich
            </Button>
          </div>
          <Typography className={classes.hotline}>
            Einsparpotenzial Hotline
          </Typography>
          <Typography className={classes.hotlineTel}>
            07141 | 258 78 - 140
          </Typography>
        </FormControl>
      </Container>
    </div>
  )
}
IndividualRequest.propTypes = {
  icon: PropTypes.string,
}
