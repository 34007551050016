import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  components: {
    // score24 elemente haben keine Rundungen
    MuiPaper: { styleOverrides: { root: { borderRadius: 0 } } },
    MuiButton: { styleOverrides: { root: { borderRadius: 0 } } },
    MuiCard: { styleOverrides: { root: { borderRadius: 0 } } },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            backgroundColor: '#707070',
          },
          '&:after': {
            backgroundColor: '#707070',
          },
        },
      },
    },
  },
  // Palette
  palette: {
    // score24 verwendet weinrot & anthrazit als themefarben
    primary: { main: '#A00523' },
    secondary: { main: '#1D1D1B' },
  },
  // Typographie
  typography: {
    // Buttons nicht automatisch uppercasen,
    button: {
      color: 'black',
      textTransform: 'none',
      fontSize: 20,
      fontFamily: ['Roboto', 'RobotoSlab'].join(','),
    },
  },
})
