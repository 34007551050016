import {
  Card,
  CardContent,
  Collapse,
  Container,
  ImageList,
  ImageListItem,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { ICO_WHITE_ARROW_RIGHT } from './../../img'

const useStyles = makeStyles(() => ({
  root: {
    background: '#FFF',
  },
  title: {
    font: '40px RobotoSlab !important',
    maxWidth: '765px',
    marginBottom: '50px !important',
  },
  tile: {
    backgroundColor: '#E6E6E6',
    padding: '80px',
    color: '#A00523',
    cursor: 'pointer',
    font: '40px RobotoSlab !important',
    position: 'absolute',
    bottom: '0px',
  },
  tileActive: {
    backgroundColor: '#A00523',
    padding: '80px',
    color: '#FFF',
    cursor: 'pointer',
    font: '40px RobotoSlab !important',
    position: 'absolute',
    bottom: '0px',
  },
  content: {
    color: 'white',
    font: '40px RobotoSlab !important',
    position: 'absolute',
    bottom: '0px',
    left: '30px',
  },
  description: {
    font: '16px Roboto !important',
    marginBottom: '20px !important',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    display: 'flex',
    textAlign: 'justify',
    hyphens: 'auto',
  },
  itemDescription: { font: '20px Roboto !important' },
}))

export const Compensation = props => {
  const classes = useStyles()
  const { data } = props
  const { items, title } = data

  const [active, setActive] = useState(0)

  return data ? (
    <div className={classes.root}>
      <Container>
        <Typography className={classes.title}>{title}</Typography>
      </Container>

      <ImageList
        xl={{ width: 1920, height: 440 }}
        cols={3}
        rowHeight={440}
        style={{ margin: 0 }}
      >
        {items.map((item, key) => {
          const { description, title } = item

          return (
            <ImageListItem
              key={key}
              className={key === active ? classes.tileActive : classes.tile}
              onClick={() => setActive(key)}
            >
              <Card style={{ position: 'relative' }}>
                <CardContent className={classes.content}>
                  {title}
                  <br />
                  <img alt={''} src={ICO_WHITE_ARROW_RIGHT} />
                  <Collapse
                    in={key === active}
                    className={classes.itemDescription}
                  >
                    {description}
                  </Collapse>
                </CardContent>
              </Card>

              <Typography className={classes.title}>
                Schritt {key + 1}: {title}
                <br />
                <img
                  alt={''}
                  src={ICO_WHITE_ARROW_RIGHT}
                  width={30}
                  height={30}
                />
              </Typography>
              <Collapse in={active === key} className={classes.itemDescription}>
                <Typography className={classes.description}>
                  {description}
                </Typography>
              </Collapse>
            </ImageListItem>
          )
        })}
      </ImageList>
    </div>
  ) : (
    ''
  )
}

Compensation.propTypes = {
  data: PropTypes.object,
}
