import { Container, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
// assets
import {
  ICO_SANDGLAS,
  ICO_VELOCITY,
  ICO_MONEY,
  ICO_REFRESH,
  ICO_TARGET,
  ICO_COMMENT,
  ICO_FOLDER,
} from '../../img'

const useStyles = makeStyles(theme => ({
  root: {
    background: '#FFF',
    paddingTop: '100px',
    paddingBottom: '100px',
  },
  container: {
    maxWidth: '765px !important',
    width: '100%',
  },
  caption: {
    font: '40px RobotoSlab !important',
    marginBottom: '50px !important',
  },
  title: {
    font: '20px Roboto-Bold !important',
    fontWeight: 'bold',
    marginTop: '20px !important',
    marginBottom: '20px !important',
    display: 'flex',
    justifyContent: 'center',
  },
  description: {
    font: '20px Roboto !important',
    paddingBottom: '20px !important',
    display: 'flex',
    textAlign: 'center',
  },
  tile: {
    padding: '80px auto',
    width: '200px !important',
    textAlign: 'center',
  },
  image: {
    width: '100px',
    height: '100px',
    objectFit: 'contain',
    transform: 'scale(0.8)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
  },
  imageHover: {
    width: '100px',
    height: '100px',
    objectFit: 'contain',
    transform: 'scale(1)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
  },
}))

export const AdvantagesOA = props => {
  const classes = useStyles()
  const { data } = props
  const { description, items, title } = data

  const [active, setActive] = useState(null)

  return data ? (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Typography className={classes.caption}>{title}</Typography>
        <Typography className={classes.description}>{description}</Typography>
      </Container>

      <Container maxWidth={'xl'}>
        <Grid container>
          {items.map((item, key) => {
            const { description, title } = item

            return (
              <Grid
                item
                key={key}
                className={classes.tile}
                onMouseOver={() => setActive(key)}
                onMouseOut={() => setActive(null)}
              >
                <img
                  alt={''}
                  className={
                    active === key ? classes.imageHover : classes.image
                  }
                  src={
                    [
                      ICO_SANDGLAS,
                      ICO_VELOCITY,
                      ICO_MONEY,
                      ICO_REFRESH,
                      ICO_TARGET,
                      ICO_COMMENT,
                      ICO_FOLDER,
                    ][key]
                  }
                />
                <Typography className={classes.title}>{title}</Typography>
                <Typography className={classes.description}>
                  {description}
                </Typography>
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </div>
  ) : (
    ''
  )
}

AdvantagesOA.propTypes = {
  data: PropTypes.object,
}
