import {
  Audit,
  Blog,
  BlogSingle,
  //Calculator,
  Co2Calculator,
  EnergyCalculator,
  GasCalculator,
  Grants,
  Home,
  Impressum,
  Privacy,
  TheCompany,
} from '../pages'

export const routes = [
  /*  {
    component: Calculator,
    path: '/calculator',
  },*/
  {
    component: EnergyCalculator,
    path: '/energy-calculator',
    pathTo: '/',
    redirect: true,
  },
  {
    component: GasCalculator,
    path: '/gas-calculator',
    pathTo: '/',
    redirect: true,
  },
  {
    component: Co2Calculator,
    path: '/co2-calculator',
    pathTo: '/',
    redirect: true,
  },
  { component: Audit, path: '/audit', pathTo: '/', redirect: true },
  { component: Grants, path: '/foerdermittel', pathTo: '/', redirect: true },
  { component: TheCompany, path: '/unternehmen', pathTo: '/', redirect: true },
  { component: Privacy, path: '/datenschutz', pathTo: '/', redirect: true },
  { component: Impressum, path: '/impressum', pathTo: '/', redirect: true },
  { component: BlogSingle, path: '/blog/:slug', pathTo: '/', redirect: true },
  { component: Blog, path: '/blog', pathTo: '/', redirect: true },
  { component: Home, path: '/', pathTo: '/', redirect: true },
]
