import {
  Button,
  Card,
  Collapse,
  Container,
  Grid,
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import DatePicker from 'react-date-picker'
//import DatePicker from 'react-date-picker/dist/entry.nostyle'
import { CommonData } from './CommonData'
// context
import FormData from '../../context/formData.context'
import PriceConditions from '../../context/priceConditions.context'
// assets
import { ICO_CALENDAR } from '../../img'
// css
import '../../css/DatePicker.css'
import { setSession } from '../../api/storage'

const useStyles = makeStyles(() => ({
  tabs: {
    marginTop: '50px',
  },

  medium: {
    /*
    '&::before': {
      top: '-10px',
      position: 'absolute',
      content: '"RECHNER"',
      font: '14px RobotoBlack',
      color: '#A00523',
    },
    */
    alignItems: 'flex-start !important',
    borderTop: 'solid 3px #E6E6E6 !important',
    fontSize: '40px !important',
    marginLeft: '12px !important',
    marginRight: '12px !important',
    paddingLeft: '0px !important',
    textAlign: 'left',
    width: '320px',
  },

  gridContainer: { paddingLeft: '80px !important' },
  gridTabForm: {
    marginLeft: '12px !important',
    marginRight: '12px !important',
    width: '320px',
  },
  tabForm: {
    marginBottom: '50px',
    width: '320px',
  },
  datePicker: {
    marginBottom: '50px',
    width: '320px',
    '& div': {
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
    },
  },
}))

export const CalcTypeSelector = () => {
  const STORAGE_ID = 'formData'
  const classes = useStyles()
  const history = useHistory()

  const { formData, setFormData } = useContext(FormData)
  const priceData = useContext(PriceConditions)

  const amountRef = useRef()
  const branchRef = useRef()

  const [anchorEl1, setAnchorEl1] = useState(null)
  const [anchorEl2, setAnchorEl2] = useState(null)

  const id = anchorEl1 ? 'simple-popover' : undefined

  const insertDots = num => String(num).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  const removeDots = num => parseInt(String(num).replace(/[^0-9]/g, ''))

  const getProvision = branch => {
    const { one, two, three } = priceData.branche.provision

    switch (branch) {
      case 1:
        return one
      case 2:
        return two
      case 3:
        return three
      default:
        console.error('unhandled getProvision:', branch)
        return branch
    }
  }

  const getAmount = amount => {
    const { one, two, three, four, five, six } = priceData.amount

    amount = parseInt(amount)

    if (amount >= 10000000) {
      return 0
    } else if (amount > 1000000) {
      return six
    } else if (amount > 750000) {
      return five
    } else if (amount > 500000) {
      return four
    } else if (amount > 250000) {
      return three
    } else if (amount > 100000) {
      return two
    } else if (amount === 100000) {
      return one
    } else {
      console.error('unhandled getAmount:', amount)
    }
  }

  const getOffPeak = branch => {
    const { one, two, three } = priceData.branche.offPeak

    switch (branch) {
      case 1:
        return one
      case 2:
        return two
      case 3:
        return three
      default:
        console.error('unhandled getOffPeak:', branch)
        return branch
    }
  }

  const getEexPeak = (year, isBase) => {
    const { eexBase, eexPeak } = priceData

    switch (year) {
      case 2021:
        return (isBase ? eexBase.y2021 : eexPeak.y2021) / 10
      case 2022:
        return (isBase ? eexBase.y2022 : eexPeak.y2022) / 10
      case 2023:
        return (isBase ? eexBase.y2023 : eexPeak.y2023) / 10
      case 2024:
        return (isBase ? eexBase.y2024 : eexPeak.y2024) / 10
      case 2025:
        return (isBase ? eexBase.y2025 : eexPeak.y2025) / 10
      case 2026:
        return (isBase ? eexBase.y2026 : eexPeak.y2026) / 10
      case 2027:
        return (isBase ? eexBase.y2027 : eexPeak.y2027) / 10
      case 2028:
        return (isBase ? eexBase.y2028 : eexPeak.y2028) / 10
      case 2029:
        return (isBase ? eexBase.y2029 : eexPeak.y2029) / 10
      default:
        console.error('unhandled getEexPeak:', year, isBase)
        break
    }
  }

  const calculate_energy = data => {
    const { amount, branch, startDate } = data

    const year1 = Number(startDate.getFullYear())
    const partYear1 = 1 - parseInt(startDate.getMonth()) / 12

    const year2 = year1 + 1
    const year3 = year1 + 2
    const year4 = year1 + 3

    const finalPrice = {
      year1: {
        one: null,
        two: null,
      },
      year2: {
        one: null,
        two: null,
      },
      year3: {
        one: null,
        two: null,
      },
    }

    const OFF_PEAK = getOffPeak(branch)

    if (partYear1 !== 1) {
      finalPrice.year1.one =
        (OFF_PEAK * getEexPeak(year1, true) +
          (1 - OFF_PEAK) * getEexPeak(year1, false)) *
        partYear1

      finalPrice.year1.two =
        (OFF_PEAK * getEexPeak(year2, true) +
          (1 - OFF_PEAK) * getEexPeak(year2, false)) *
        (1 - partYear1)

      finalPrice.year2.one =
        (OFF_PEAK * getEexPeak(year2, true) +
          (1 - OFF_PEAK) * getEexPeak(year2, false)) *
        partYear1

      finalPrice.year2.two =
        (OFF_PEAK * getEexPeak(year3, true) +
          (1 - OFF_PEAK) * getEexPeak(year3, false)) *
        (1 - partYear1)

      finalPrice.year3.one =
        (OFF_PEAK * getEexPeak(year3, true) +
          (1 - OFF_PEAK) * getEexPeak(year3, false)) *
        partYear1

      finalPrice.year3.two =
        (OFF_PEAK * getEexPeak(year4, true) +
          (1 - OFF_PEAK) * getEexPeak(year4, false)) *
        (1 - partYear1)
    } else {
      finalPrice.year1.one =
        OFF_PEAK * getEexPeak(year1, true) +
        (1 - OFF_PEAK) * getEexPeak(year1, false)
      finalPrice.year2.one =
        OFF_PEAK * getEexPeak(year2, true) +
        (1 - OFF_PEAK) * getEexPeak(year2, false)
      finalPrice.year3.one =
        OFF_PEAK * getEexPeak(year3, true) +
        (1 - OFF_PEAK) * getEexPeak(year3, false)
    }

    const provisionBranch = getProvision(branch)
    const provisionAmount = getAmount(amount)

    let yearPrice1 =
      finalPrice.year1.one +
      finalPrice.year1.two +
      provisionBranch +
      provisionAmount

    let yearPrice2 =
      finalPrice.year2.one +
      finalPrice.year2.two +
      provisionBranch +
      provisionAmount

    let yearPrice3 =
      finalPrice.year3.one +
      finalPrice.year3.two +
      provisionBranch +
      provisionAmount

    const averagePrices = [
      yearPrice1,
      (yearPrice1 + yearPrice2) / 2,
      (yearPrice1 + yearPrice2 + yearPrice3) / 3,
    ]
    // const finalPrices = [yearPrice1, yearPrice2, yearPrice3]

    return averagePrices
  }

  const calculate_gas = data => {
    const { amount } = data

    let price1 = 0
    const { factors: factors1 } = priceData.gasData[0]

    if (amount >= 1200000) {
      price1 = factors1[3].value
    } else if (amount >= 800000 && amount < 1200000) {
      price1 = factors1[2].value
    } else if (amount >= 300000 && amount < 800000) {
      price1 = factors1[1].value
    } else if (amount >= 100000 && amount < 300000) {
      price1 = factors1[0].value
    }

    let price2 = 0
    const { factors: factors2 } = priceData.gasData[1]

    if (amount >= 1200000) {
      price2 = factors2[3].value
    } else if (amount >= 800000 && amount < 1200000) {
      price2 = factors2[2].value
    } else if (amount >= 300000 && amount < 800000) {
      price2 = factors2[1].value
    } else if (amount >= 100000 && amount < 300000) {
      price2 = factors2[0].value
    }

    let price3 = 0
    const { factors: factors3 } = priceData.gasData[2]

    if (amount >= 1200000) {
      price3 = factors3[3].value
    } else if (amount >= 800000 && amount < 1200000) {
      price3 = factors3[2].value
    } else if (amount >= 300000 && amount < 800000) {
      price3 = factors3[1].value
    } else if (amount >= 100000 && amount < 300000) {
      price3 = factors3[0].value
    }

    const averagePrices = [price1, price2, price3]

    //console.log(averagePrices)

    return averagePrices
  }

  useEffect(() => setSession(STORAGE_ID, formData), [formData])

  // hide energy, gas, co2 offers, whenever formData variable changes or url
  useEffect(
    () => {
      setFormData({
        ...formData,
        showEnergyOffer: false,
        showGasOffer: false,
        showCo2Offer: false,
      })
    },
    [formData.amount, formData.branch, formData.medium, location.pathname]
  )

  return (
    <Card elevation={0} style={{ overflow: 'auto' }}>
      <Tabs
        centered
        className={classes.tabs}
        indicatorColor={'primary'}
        onChange={(e, medium) => {
          setFormData({ ...formData, medium: medium })

          switch (medium) {
            case 'energy':
              if (location.pathname !== '/') history.push('/energy-calculator')
              break
            case 'gas':
              if (location.pathname !== '/') history.push('/gas-calculator')
              break
            case 'co2':
              if (location.pathname !== '/') history.push('/co2-calculator')
              break
            default:
              console.error('unhandled medium', medium)
              break
          }
        }}
        TabIndicatorProps={{
          style: { height: '3px', top: '0px', width: '320px' },
        }}
        textColor={'secondary'}
        value={formData.medium}
      >
        {[
          { title: 'Strom', value: 'energy' },
          { title: 'Gas', value: 'gas' },
          { title: 'CO₂', value: 'co2' },
        ].map((item, key) => {
          return (
            <Tab
              className={classes.medium}
              key={key}
              label={item.title}
              value={item.value}
            />
          )
        })}
      </Tabs>

      <Collapse in={formData.medium !== 'co2'}>
        <Container className={classes.gridContainer}>
          <Grid container spacing={0}>
            <Grid item className={classes.gridTabForm}>
              <TextField
                className={classes.tabForm}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={'end'}>kWh</InputAdornment>
                  ),
                }}
                onClick={() => {
                  if (formData.amount === 'Jahresbedarf')
                    setFormData({
                      ...formData,
                      amount: '',
                    })
                }}
                onChange={e =>
                  setFormData({
                    ...formData,
                    amount: removeDots(e.target.value),
                  })
                }
                ref={amountRef}
                value={insertDots(formData.amount)}
                //type={formData.amount === 'Jahresbedarf' ? 'string' : 'number'}
                variant={'standard'}
              />
              <Popover
                anchorEl={anchorEl1}
                anchorOrigin={{
                  horizontal: 'left',
                  vertical: 'bottom',
                }}
                id={id}
                onClose={() => setAnchorEl1(null)}
                open={Boolean(anchorEl1)}
              >
                <Typography
                  sx={{ p: 2 }}
                  style={{ background: '#A00523', color: 'white' }}
                >
                  Bitte geben Sie einen Verbrauch über 100.000 kWh ein.
                </Typography>
              </Popover>
            </Grid>

            <Grid item className={classes.gridTabForm}>
              <Select
                className={classes.tabForm}
                onChange={e =>
                  setFormData({ ...formData, branch: e.target.value })
                }
                ref={branchRef}
                value={formData.branch}
                variant={'standard'}
              >
                <MenuItem value={0}>Branche</MenuItem>
                <MenuItem value={1}>Produktion (1-Schicht)</MenuItem>
                <MenuItem value={2}>Produktion (Mehr-Schicht)</MenuItem>
                <MenuItem value={3}>Dienstleistungen</MenuItem>
              </Select>
              <Popover
                anchorEl={anchorEl2}
                anchorOrigin={{
                  horizontal: 'left',
                  vertical: 'bottom',
                }}
                id={id}
                onClose={() => setAnchorEl2(null)}
                open={Boolean(anchorEl2)}
              >
                <Typography
                  sx={{ p: 2 }}
                  style={{ background: '#A00523', color: 'white' }}
                >
                  Bitte wählen Sie ihre passende Branche aus.
                </Typography>
              </Popover>
            </Grid>

            <Grid item className={classes.gridTabForm}>
              <DatePicker
                monthAriaLabel={'Month'}
                yearAriaLabel={'Year'}
                clearIcon={null}
                calendarIcon={<img src={ICO_CALENDAR} alt={''} />}
                className={classes.datePicker}
                maxDetail={'year'}
                maxDate={new Date(new Date().getFullYear() + 3, 11, 31)}
                onChange={e => {
                  const startDate = new Date(
                    e.getFullYear(),
                    e.getMonth(),
                    1,
                    12,
                    0,
                    0
                  )

                  setFormData({ ...formData, startDate })
                }}
                minDate={new Date(new Date().getFullYear() + 1, 0, 1)}
                value={formData.startDate}
              />
            </Grid>

            <Grid item className={classes.gridTabForm} />
            <Grid item className={classes.gridTabForm} />

            <Grid item className={classes.gridTabForm}>
              <Button
                className={classes.tabForm}
                onClick={() => {
                  if (isNaN(formData.amount) || formData.amount < 100000) {
                    // no amount specified or threshold missed; show hint
                    setAnchorEl1(amountRef.current)
                  } else if (formData.branch === 0) {
                    setAnchorEl2(branchRef.current)
                  } else {
                    // form check passed
                    const showEnergyOffer =
                      formData.medium === 'energy' ? true : false
                    const showGasOffer =
                      formData.medium === 'gas' ? true : false
                    const showCo2Offer =
                      formData.medium === 'co2' ? true : false

                    //console.log(formData)
                    setFormData({
                      ...formData,
                      finalPrices:
                        formData.medium === 'gas'
                          ? calculate_gas(formData)
                          : calculate_energy(formData),
                      showEnergyOffer,
                      showGasOffer,
                      showCo2Offer,
                    })
                  }
                }}
                variant={'contained'}
              >
                Berechnen
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Collapse>
      <Collapse in={formData.medium === 'co2'}>
        <CommonData />
      </Collapse>
    </Card>
  )
}

CalcTypeSelector.propTypes = {
  medium: PropTypes.string,
}
