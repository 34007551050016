import { Button, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PropTypes } from 'prop-types'
import React from 'react'

const useStyles = makeStyles(() => ({
  root: {
    background: '#FFF',
    paddingTop: '200px',
    paddingBottom: '200px',
    width: '100%',
    maxWidth: '1920px',
  },
  leftSide: {
    background: 'lightgray',
    paddingLeft: '200px',
    paddingTop: '50px',
  },
  headline: {
    font: '14px Roboto-Bold !important',
    textTransform: 'uppercase',
    marginBottom: '50px',
  },
  fullName: {
    font: '40px RobotoSlab !important',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  telephone: {
    font: '20px Roboto !important',
    paddingBottom: '50px',
  },
  button: {
    width: '320px',
  },
  picture: {
    objectFit: 'cover',
    width: '100%',
    height: 'auto',
    filter: 'grayscale(100%)',
  },
}))

export const Contact = props => {
  const classes = useStyles()
  const { email, headline, fullName, telephone, picture } = props.data
  const { REACT_APP_BASE_URL: BASE_URL } = process.env
  const imgUrl = picture ? `${BASE_URL}${picture.url}` : null

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={6} className={classes.leftSide}>
          <Typography className={classes.headline}>{headline}</Typography>
          <Typography className={classes.fullName}>{fullName}</Typography>
          <Typography className={classes.telephone}>{telephone}</Typography>
          <Button
            className={classes.button}
            variant={'contained'}
            onClick={() => (window.location = `mailto:${email}`)}
          >
            E-Mail schreiben
          </Button>
        </Grid>
        <Grid item xs={6}>
          <img className={classes.picture} alt={''} src={imgUrl} />
        </Grid>
      </Grid>
    </div>
  )
}

Contact.propTypes = {
  data: PropTypes.object,
}
