import {
  Card,
  CardContent,
  Container,
  ImageList,
  ImageListItem,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React from 'react'
// assets
import { ICO_LION } from '../../img'

const useStyles = makeStyles(() => ({
  root: {
    background: '#FFF',
    paddingTop: '150px',
    paddingBottom: '150px',
  },
  title: {
    font: '60px RobotoSlab !important',
  },
  content: {
    color: 'white',
    width: '498px',
    height: '440px',
    background: 'lightgray',
  },
  imageList: {
    marginLeft: '200px',
  },
  thumbnail: {
    objectFit: 'cover',
    width: '100%',
    height: 'auto',
    filter: 'grayscale(100%)',
  },
}))
// https://codepen.io/sosuke/pen/Pjoqqp

export const BlogList = props => {
  const classes = useStyles()
  const { data } = props
  const { header } = data
  const { blogItems } = header
  const { REACT_APP_BASE_URL: BASE_URL } = process.env

  return (
    <div className={classes.root}>
      <Container>
        <Typography className={classes.title}>Blog</Typography>
      </Container>

      <ImageList
        className={classes.imageList}
        xl={{ width: 1920, height: 440 }}
        cols={10}
        rowHeight={440}
        onScroll={event => {
          if (event.target.scrollTop === event.target.scrollHeight) {
            // user is at the end of the list so load more items
          }
        }}
      >
        {blogItems.map((item, key) => {
          const { content, title, thumbnail } = item
          const src =
            thumbnail && thumbnail.url ? BASE_URL + thumbnail.url : ICO_LION

          return (
            <ImageListItem key={key}>
              <Card style={{ position: 'relative' }}>
                <CardContent className={classes.content}>
                  {title}

                  {content}
                  <div>
                    <img src={src} alt={''} className={classes.thumbnail} />
                  </div>
                </CardContent>
              </Card>
            </ImageListItem>
          )
        })}
      </ImageList>
    </div>
  )
}

BlogList.propTypes = {
  data: PropTypes.any,
}
