import { Container, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { useOnScreen } from '../../hooks/useOnScreen'

const useStyles = makeStyles(theme => ({
  root: {
    background: '#FFF',
    overflow: 'hidden',
    paddingBottom: '150px',
    paddingTop: '150px',
    position: 'relative',
  },
  container: {
    maxWidth: '765px !important',
    width: '100%',
  },
  title: {
    font: '60px RobotoSlab !important',
    marginBottom: '50px !important',
  },
  description: {
    font: '20px Roboto !important',
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
    textAlign: 'justify',
  },
  cite: {
    position: 'absolute',
    backgroundColor: '#A00523',
    backgroundBlendMode: 'multiply',
    //mixBlendMode: 'multiply',
    padding: '20px 50px',
    width: '765px',
    right: '0px',
    marginRight: '200px',
    marginTop: '50px',
    zIndex: '3',
  },
  text: {
    color: 'white',
    font: '60px RobotoSlab !important',
    width: '700px',
  },
  source: {
    marginTop: '20px !important',
    marginBottom: '20px !important',
    color: 'white',
    font: '14px Roboto !important',
  },
  picture: {
    position: 'relative',
    width: '1250px',
    marginLeft: '200px',
    marginTop: '150px',
    zIndex: '1',
  },

  scaleQuote: {
    position: 'absolute',
    left: '-10px',
    marginTop: '-100px',
    zIndex: '0',
    transform: 'scale(0.8)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
  },
  scaleQuoteDown: {
    position: 'absolute',
    left: '-10px',
    marginTop: '-100px',
    zIndex: '0',
    transform: 'scale(1)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
  },
}))

export const SingleText = props => {
  const classes = useStyles()
  const { REACT_APP_BASE_URL: BASE_URL } = process.env
  const { data, icon } = props

  const ref = useRef()
  const isVisible = useOnScreen(ref)

  if (!data) return <div />

  const { cite, description, picture, source, title } = data
  const imgUrl = picture ? `${BASE_URL}${picture.url}` : null

  return (
    <div className={classes.root}>
      {/* render icon if provided */}
      {icon && (
        <img
          alt={''}
          className={isVisible ? classes.scaleQuoteDown : classes.scaleQuote}
          src={icon}
        />
      )}

      <Container className={classes.container}>
        {/* headline */}
        <Typography className={classes.title}>{title}</Typography>

        {/* text */}
        <Typography ref={ref} className={classes.description}>
          {description}
        </Typography>
      </Container>

      {cite &&
        source && (
          <div className={classes.cite}>
            <Typography className={classes.text}>{cite}</Typography>
            <Typography className={classes.source}>{source}</Typography>
          </div>
        )}

      {imgUrl && <img alt={''} className={classes.picture} src={imgUrl} />}
    </div>
  )
}

SingleText.propTypes = {
  data: PropTypes.object,
  icon: PropTypes.string,
}
