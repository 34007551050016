import {
  Card,
  CardContent,
  CardMedia,
  Collapse,
  Container,
  ImageList,
  ImageListItem,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PropTypes } from 'prop-types'
import React, { useState } from 'react'
import { ICO_WHITE_ARROW_RIGHT } from '../../img'

const useStyles = makeStyles(() => ({
  root: {
    background: '#FFF',
    paddingBottom: '50px',
    paddingop: '150px',
  },
  title: {
    font: '40px RobotoSlab !important',
    marginBottom: '50px !important',
  },
  description: {
    font: '20px Roboto !important',
    marginTop: '20px',
    maxWidth: '765px !important',
    whiteSpace: 'pre-line',
    textAlign: 'justify',
  },
  content: {
    color: 'white',
    font: '40px RobotoSlab !important',
    position: 'absolute',
    bottom: '0px',
    left: '30px',
    textAlign: 'justify',
  },
  itemDescription: { font: '20px Roboto !important' },
}))

export const Potential = props => {
  const classes = useStyles()
  const { data } = props
  const { description, items, title } = data
  const { REACT_APP_BASE_URL: BASE_URL } = process.env

  const [active, setActive] = useState(null)

  return data ? (
    <div className={classes.root}>
      <Container>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.description}>{description}</Typography>
      </Container>

      <ImageList xl={{ width: 1920, height: 440 }} cols={3} rowHeight={440}>
        {items.map((item, key) => {
          const { description, picture, title } = item
          const imgUrl = `${BASE_URL}${picture.url}`

          return (
            <ImageListItem key={key}>
              <Card
                onMouseOut={() => setActive(null)}
                onMouseOver={() => setActive(key)}
                style={{ position: 'relative' }}
              >
                <CardMedia
                  style={{
                    filter: `blur(${active === key ? 50 : 0}px)`,
                    transform: 'scale(1.1)',
                  }}
                  component={'img'}
                  height={'440'}
                  image={imgUrl}
                />
                <CardContent className={classes.content}>
                  {title}
                  <br />
                  <img alt={''} src={ICO_WHITE_ARROW_RIGHT} />
                  <Collapse
                    in={key === active}
                    className={classes.itemDescription}
                  >
                    {description}
                  </Collapse>
                </CardContent>
              </Card>
            </ImageListItem>
          )
        })}
      </ImageList>
    </div>
  ) : (
    ''
  )
}

Potential.propTypes = {
  data: PropTypes.object,
}
