import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

export const Scene = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  maxwidth: ${props => props.maxWidth};
  maxheight: ${props => props.maxHeight};
  background-color: ${props => props.bgColor};
  overflow: ${props => props.overflow};
`

Scene.defaultProps = {
  /* dimension */
  width: '100%',
  height: '100%',
  maxWidth: '1920px',
  maxHeight: '1080px',
  /* backgroundColor */
  bgColor: 'none',
  /* overflow */
  overflow: 'auto',
}

Scene.propTypes = {
  children: PropTypes.any.isRequired,
}
