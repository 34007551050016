import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItem,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Chart from 'react-google-charts'
import { isValidLength, isValidEmail } from '../../api/util'
//context
import PriceConditions from '../../context/priceConditions.context'
import FormData from '../../context/formData.context'
// assets
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { ICO_CHECKBOX } from '../../img'
/*
import COMPENSATION_0 from '../../img/placeholder.png'
import COMPENSATION_1 from '../../img/placeholder.png'
import COMPENSATION_2 from '../../img/placeholder.png'
import COMPENSATION_3 from '../../img/placeholder.png'
*/

const useStyles = makeStyles(() => ({
  root: { backgroundColor: '#FFF !important' },
  caption: {
    font: '40px Roboto !important',
    maxWidth: '550px',
    width: '100%',
    marginBottom: '20px',
  },
  caption2: {
    font: '40px Roboto !important',
    maxWidth: '100%',
    width: '100%',
    marginBottom: '20px',
  },
  formControl: {
    marginTop: '80px !important',
    maxWidth: '500px',
  },
  bold: {
    fontFamily: 'RobotoBlack !important',
    marginLeft: '20px',
  },
  title: {
    font: '40px RobotoSlab !important',
    marginTop: '50px !important',
  },
  slide: {
    backgroundColor: '#E6E6E6',
    width: '1518px',
    height: '750px',
    paddingTop: '50px',
    paddingLeft: '220px',
    paddingRight: '220px',
  },
  btnBack: {
    backgroundColor: '#FFF !important',
    width: '243px',
    border: 'solid 1px #000 !important',
    padding: '10px',
    font: '20px Roboto !important',
    color: 'black !important',
    marginRight: '20px !important',
  },
  btnForward: {
    backgroundColor: '#E6E6E6 !important',
    width: '243px',
    border: 'solid 1px #E6E6E6 !important',
    padding: '10px',
    font: '20px Roboto !important',
    color: 'black !important',
  },
  btnForwardDisabled: {
    backgroundColor: '#E6E6E6 !important',
    width: '243px',
    border: 'solid 1px #E6E6E6 !important',
    textAlign: 'center',
    padding: '10px',
    font: '20px Roboto !important',
    color: '#CCC !important',
  },
  tabs: {
    textAlign: 'left !important',
    width: '225px',
    fontSize: '12px Roboto !important',
    paddingLeft: '0 !important',
    paddingBottom: '0 !important',
    marginTop: '10px !important',
    marginLeft: '10px !important',
    marginRight: '10px !important',
    alignItems: 'flex-start !important',
    borderBottom: 'solid 1px #1B1D1D !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  notice: {
    position: 'absolute',
    bottom: '20px',
  },
  notice2: {
    marginTop: '40px !important',
  },
  curSlide: {
    position: 'absolute',
    left: '100px',
    top: '30px',
    font: '100px RobotoSlabThin !important',
  },
  _footer: { position: 'absolute', bottom: '10px', right: '10px' },
  table: { background: '#E6E6E6 !important' },
  section: { marginTop: '50px' },
  button: {
    font: '20px Roboto !important',
    backgroundColor: '#A00523 !important',
    color: 'white !important',
    width: '300px',
  },
  dialogTitle: {
    background: '#A00523',
    color: 'white',
  },
  requestOffer: {
    background: '#a00523 !important',
    color: 'white !important',
    width: '300px',
  },
  requestOffer_disabled: { background: '#CCCCCC !important', width: '300px' },
}))

export const CommonData = () => {
  const classes = useStyles()
  const co2Ref = useRef()
  const priceData = useContext(PriceConditions)
  const { formData } = useContext(FormData)

  const [tab, setTab] = useState(0)
  const [subTab, setSubTab] = useState(0)

  const [name, setName] = useState('')
  const [company, setCompany] = useState('')

  const [isAuthorized, setIsAuthorized] = useState(false)

  const userLocales = 'de-DE'
  const precision = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }

  const [energyData, setEnergyData] = useState({})
  const [emissionsData, setEmissionsData] = useState({})
  const [materialData, setMaterialData] = useState({})
  const [saldo, setSaldo] = useState(0)

  const { REACT_APP_BASE_URL: BASE_URL } = process.env

  const [scope1, setScope1] = useState(0)
  const [scope2, setScope2] = useState(0)
  const [scope3, setScope3] = useState(0)

  const [chartData, setChartData] = useState([
    ['Element', 'Wert', { role: 'style' }],
    ['Liegenschaften', 900, '#5DA5DD'],
    ['Transport', 100, '#5DA5DD'],
    ['Sonstiges', 20, '#5DA5DD'],
    ['Klima-Invest', -120, 'color: #5DA5DD'],
  ])

  const [pieData, setPieData] = useState([
    ['Element', 'Wert', { role: 'style' }],
    ['Scope 1', 0, '#B84746'],
    ['Scope 2', 0, '#91B04D'],
    ['Scope 3', 0, '#538DCC'],
    ['RFI', 0, 'color: #FCCB05'],
  ])

  const insertDots = num => String(num).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  const removeDots = num => parseInt(String(num).replace(/[^0-9]/g, ''))

  const digitGrouping = digit =>
    Number(digit).toLocaleString('de-DE', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })

  const { co2: factors } = priceData

  const [config, setConfig] = useState({
    // Tab 1 (Energiedaten)
    consumption: {
      electricity: '',
      isEco: false,
      naturalGas: '',
      oilHeating: '',
      teleHeating: '',
    },
    // Tab 2 (Kraftstoffbezogene Emissionen)
    emissions: {
      diesel: '',
      benzine: '',
      publicTransport: '',
      shortHaulFlight: '',
      mediumHaulFlight: '',
      longHaulFlight: '',
    },
    // Tab 3 (Material & Sonstiges)
    material: {
      paper: '',
      waste: '',
      supplier: '',
      customer: '',
    },
    // Tab 4 (Klima-Invest)
    climateInvest: '',
    // compensation; should be one of the four
    compensationMethod: null,
  })

  const [equivalents, setEquivalents] = useState({
    // Tab 1 (Energiedaten)
    consumption: {
      electricity: '',
      isEco: false,
      naturalGas: '',
      oilHeating: '',
      teleHeating: '',
    },
    // Tab 2 (Kraftstoffbezogene Emissionen)
    emissions: {
      diesel: '',
      benzine: '',
      publicTransport: '',
      shortHaulFlight: '',
      mediumHaulFlight: '',
      longHaulFlight: '',
    },
    // Tab 3 (Material & Sonstiges)
    material: {
      paper: '',
      waste: '',
      supplier: '',
      customer: '',
    },
    // Tab 4 (Klima-Invest)
    climateInvest: '',
    // compensation; should be one of the four
    compensationMethod: null,
  })

  useEffect(
    () => {
      co2Ref.current.scrollLeft = tab * 1498
    },
    [tab]
  )

  useEffect(
    () => {
      const {
        electricity: energy,
        naturalGas,
        oilHeating,
        teleHeating,
      } = equivalents.consumption

      let total = energy + naturalGas + oilHeating + teleHeating
      let heating = naturalGas + oilHeating + teleHeating
      let electricity = energy

      if (isNaN(total)) total = 0
      if (isNaN(heating)) heating = 0
      if (isNaN(electricity)) electricity = 0

      setEnergyData({ total, heating, electricity })
    },
    [equivalents]
  )

  useEffect(
    () => {
      const {
        diesel,
        benzine,
        publicTransport,
        shortHaulFlight,
        mediumHaulFlight,
        longHaulFlight,
      } = equivalents.emissions

      let total =
        diesel +
        benzine +
        publicTransport +
        shortHaulFlight +
        mediumHaulFlight +
        longHaulFlight
      let motorPool = diesel + benzine
      let businessTrips = publicTransport

      if (isNaN(total)) total = 0
      if (isNaN(motorPool)) motorPool = 0
      if (isNaN(businessTrips)) businessTrips = 0

      setEmissionsData({ total, motorPool, businessTrips })
    },
    [equivalents]
  )

  useEffect(
    () => {
      const { customer, paper, supplier, waste } = equivalents.material

      let total =
        Number(paper) + Number(waste) + Number(supplier) + Number(customer)

      setMaterialData({ total, paper })
    },
    [equivalents]
  )

  /*
Erdgas          scope1
Heizöl          scope1
Diesel          scope1
Benzin          scope1

Fernwärme       scope2
Strom           scope2

ÖPNV            scope3
Flüge           scope3
Papier          scope3
Abfall          scope3
vorgel. co2e    scope3
nachgel. co2e   scope3
*/

  useEffect(
    () => {
      const { climateInvest, consumption, emissions, material } = equivalents

      setScope1(
        Number(consumption.naturalGas) +
          Number(consumption.oilHeating) +
          Number(emissions.diesel) +
          Number(emissions.benzine)
      )

      setScope2(
        Number(consumption.teleHeating) + Number(consumption.electricity)
      )

      setScope3(
        Number(emissions.publicTransport) +
          Number(emissions.shortHaulFlight) +
          Number(emissions.mediumHaulFlight) +
          Number(emissions.longHaulFlight) +
          Number(material.paper) +
          Number(material.waste) +
          Number(material.supplier) +
          Number(material.customer)
      )

      setPieData([
        ['Element', 'Wert', { role: 'style' }],
        ['Scope 1', Number((scope1 / saldo) * 100), '#B84746'],
        ['Scope 2', Number((scope2 / saldo) * 100), '#91B04D'],
        ['Scope 3', Number((scope3 / saldo) * 100), '#538DCC'],
        ['RFI', 0, 'color: #FCCB05'],
      ])

      const liegenschaften =
        Number(consumption.naturalGas) +
        Number(consumption.oilHeating) +
        Number(consumption.teleHeating) +
        Number(consumption.electricity)

      const transport =
        Number(emissions.diesel) +
        Number(emissions.benzine) +
        Number(emissions.publicTransport) +
        Number(emissions.shortHaulFlight) +
        Number(emissions.mediumHaulFlight) +
        Number(emissions.longHaulFlight)

      const other =
        Number(material.paper) +
        Number(material.waste) +
        Number(material.supplier) +
        Number(material.customer)

      setChartData([
        ['Element', 'Wert', { role: 'style' }],
        ['Liegenschaften', liegenschaften, '#5DA5DD'],
        ['Transport', transport, '#5DA5DD'],
        ['Sonstiges', other, '#5DA5DD'],
        ['Klima-Invest', -1 * climateInvest, 'color: #5DA5DD'],
      ])
    },
    [equivalents]
  )

  useEffect(
    () => {
      const saldo =
        Number(energyData.total) +
        Number(emissionsData.total) +
        Number(materialData.total)

      setSaldo(saldo)
    },
    [equivalents, energyData.total, emissionsData.total, materialData.total]
  )

  const [showRequestOffer, setShowRequestOffer] = useState(false)
  //const [offerPrice, setOfferPrice] = useState(0)
  const [gender, setGender] = useState('')
  //const [name, setName] = useState('')
  //const [company, setCompany] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [privacyAck, setPrivacyAck] = useState(false)
  const [preflight, setPreflight] = useState(false)

  return (
    <div className={classes.root}>
      <Tabs
        centered
        indicatorColor={'primary'}
        onChange={(e, tab) => setTab(tab)}
        textColor={'primary'}
        value={tab}
      >
        <Tab
          className={classes.tabs}
          value={0}
          label={'Allgemeine Informationen'}
        />
        <Tab
          className={classes.tabs}
          value={1}
          label={'Datenerfassung'}
          disabled={!isValidLength(name, 2) || !isValidLength(company, 2)}
        />
        <Tab
          className={classes.tabs}
          value={2}
          label={'CO₂-Footprint'}
          disabled={
            !isValidLength(name, 2) ||
            !isValidLength(company, 2) ||
            !isAuthorized
          }
        />
        {/*
        <Tab
          className={classes.tabs}
          value={3}
          label={'Neutralstellung / Kompensation'}
        />
        <Tab className={classes.tabs} value={3} label={'Angebot / Abschluss'} />
        */}
      </Tabs>

      <Dialog
        onClose={() => setShowRequestOffer(false)}
        open={showRequestOffer}
      >
        <DialogTitle className={classes.dialogTitle}>
          Footprint anfordern
        </DialogTitle>

        <DialogContent>
          <form>
            <Container maxWidth={'md'}>
              <FormControl fullWidth className={classes.title}>
                <InputLabel>Anrede</InputLabel>
                <Select
                  label={'Anrede'}
                  onChange={e => setGender(e.target.value)}
                  required
                  value={gender}
                  variant={'standard'}
                >
                  <MenuItem value={''}>Bitte wählen</MenuItem>
                  <MenuItem value={'Herr'}>Herr</MenuItem>
                  <MenuItem value={'Frau'}>Frau</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  className={classes.title}
                  error={!isValidLength(name, 2) && preflight}
                  label={'Vor- und Nachname'}
                  onChange={e => setName(e.target.value)}
                  required
                  type={'text'}
                  value={name}
                  variant={'standard'}
                />
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  className={classes.title}
                  error={!isValidLength(company, 2) && preflight}
                  label={'Firma'}
                  onChange={e => setCompany(e.target.value)}
                  required
                  type={'text'}
                  value={company}
                  variant={'standard'}
                />
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  className={classes.title}
                  label={'Telefon'}
                  onChange={e => setPhone(e.target.value)}
                  type={'tel'}
                  value={phone}
                  variant={'standard'}
                />
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  className={classes.title}
                  error={!isValidEmail(email) && preflight}
                  label={'E-Mail'}
                  onChange={e => setEmail(e.target.value)}
                  required
                  type={'email'}
                  value={email}
                  variant={'standard'}
                />
              </FormControl>

              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checkedIcon={<CheckCircleIcon />}
                      icon={<img alt={''} src={ICO_CHECKBOX} />}
                      checked={privacyAck}
                      onClick={() => {
                        setPrivacyAck(!privacyAck)
                        setPreflight(!preflight)
                      }}
                    />
                  }
                  label={
                    'Ich akzeptiere die Datenschutzbestimmungen des Anbieters'
                  }
                />
              </FormControl>
            </Container>
          </form>
        </DialogContent>

        <DialogActions>
          <Button
            className={
              privacyAck ? classes.requestOffer : classes.requestOffer_disabled
            }
            disabled={
              !privacyAck ||
              !isValidEmail(email) ||
              !isValidLength(name, 2) ||
              !isValidLength(company, 2)
            }
            onClick={() => {
              setPreflight(true)
              fetch(`${BASE_URL}/request-offer`, {
                method: 'POST',
                headers: {
                  accept: 'application/json, text/plain, */*',
                  'Content-Type': 'application/json',
                },
                body: `{"name": "${name}", "email": "${email}", "company": "${company}", "phone": "${phone}", "gender": "${gender}", "medium": "${
                  formData.medium
                }", "config": ${JSON.stringify(config)}}`,
              })
                .then(() => {
                  setShowRequestOffer(false)
                  setIsAuthorized(true)
                  setTab(2)
                })
                .catch(err => {
                  console.error('error', err)
                })
            }}
          >
            Jetzt anfordern
          </Button>
        </DialogActions>
      </Dialog>

      <Container maxWidth={'xl'}>
        <Stack
          direction={'row'}
          ref={co2Ref}
          style={{
            overflow: 'hidden',
            background: '#E6E6E6',
            marginTop: '50px',
            marginBottom: '20px',
          }}
        >
          {/* Allgemeine Informationen */}

          <ListItem>
            <Typography className={classes.curSlide}>{tab + 1}.</Typography>

            <div className={classes.slide}>
              <Typography className={classes.caption}>
                Erfassen und Pflegen von Allgemeinen Informationen.
              </Typography>

              <FormControl fullWidth>
                <TextField
                  className={classes.formControl}
                  label={'Vor- und Nachname'}
                  onChange={e => setName(e.target.value)}
                  required
                  value={name}
                  variant={'standard'}
                />

                <TextField
                  className={classes.formControl}
                  label={'Firma'}
                  onChange={e => setCompany(e.target.value)}
                  required
                  value={company}
                  variant={'standard'}
                />
              </FormControl>

              <Typography className={classes.notice}>
                * Pflichtangaben
              </Typography>
            </div>
          </ListItem>
          {/* Datenerfassung */}
          <ListItem>
            <Typography className={classes.curSlide}>{tab + 1}.</Typography>

            <div className={classes.slide} style={{ overflow: 'auto' }}>
              <Typography className={classes.caption2}>
                Erfassen und Pflegen von Energie- und Verbrauchsdaten zur
                Berechnung des CO₂-Footprints.
              </Typography>

              <Tabs
                centered
                indicatorColor={'primary'}
                onChange={(e, subTab) => setSubTab(subTab)}
                scrollButtons={'auto'}
                textColor={'primary'}
                value={subTab}
                variant={'fullWidth'}
              >
                <Tab
                  className={classes.tabs}
                  label={
                    <>
                      <Typography variant={'caption'}>2.1</Typography>
                      <Typography variant={'title'}>Energiedaten</Typography>
                    </>
                  }
                  value={0}
                />
                <Tab
                  className={classes.tabs}
                  label={
                    <>
                      <Typography variant={'caption'}>2.2</Typography>
                      <Typography variant={'title'}>
                        Kraftstoffbezogene Emissionen
                      </Typography>
                    </>
                  }
                  value={1}
                />
                <Tab
                  className={classes.tabs}
                  label={
                    <>
                      <Typography variant={'caption'}>2.3</Typography>
                      <Typography variant={'title'}>
                        Material &amp; Sonstiges
                      </Typography>
                    </>
                  }
                  value={2}
                />
                <Tab
                  className={classes.tabs}
                  label={
                    <>
                      <Typography variant={'caption'}>2.4</Typography>
                      <Typography variant={'title'}>Klima-Invest</Typography>
                    </>
                  }
                  value={3}
                />
              </Tabs>
              <>
                {/* Energiedaten */}
                {subTab === 0 && (
                  <Grid container>
                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          label={'Stromverbrauch in kWh'}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                          }}
                          onChange={e => {
                            const { consumption } = config
                            consumption.electricity = removeDots(e.target.value)
                            equivalents.consumption.electricity =
                              consumption.electricity *
                                (consumption.isEco
                                  ? factors.ecoElectricity
                                  : factors.electricity) || 0
                            setConfig({ ...config, consumption })
                            setEquivalents({
                              ...equivalents,
                              consumption: equivalents.consumption,
                            })
                          }}
                          //type={'number'}
                          value={insertDots(config.consumption.electricity)}
                          variant={'standard'}
                        />
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <Typography style={{ marginLeft: '200px' }}>
                          CO₂-Äquivalent in t{' '}
                          <strong className={classes.bold}>
                            {digitGrouping(equivalents.consumption.electricity)}
                          </strong>
                        </Typography>
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={config.consumption.isEco}
                            color={'primary'}
                            onChange={() => {
                              const { consumption } = config
                              consumption.isEco = !consumption.isEco
                              equivalents.consumption.isEco = consumption.isEco

                              setConfig({ ...config, consumption })

                              equivalents.consumption.electricity =
                                consumption.electricity *
                                (consumption.isEco
                                  ? factors.ecoElectricity
                                  : factors.electricity)
                              setEquivalents({
                                ...equivalents,
                                consumption: equivalents.consumption,
                              })
                            }}
                          />
                        }
                        label={'Ökostrom'}
                      />
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          onChange={e => {
                            const { consumption } = config
                            consumption.naturalGas = removeDots(e.target.value)
                            equivalents.consumption.naturalGas =
                              consumption.naturalGas * factors.naturalGas || 0

                            setConfig({ ...config, consumption })
                            setEquivalents({
                              ...equivalents,
                              consumption: equivalents.consumption,
                            })
                          }}
                          label={'Erdgas in kWh'}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                          }}
                          //type={'number'}
                          value={insertDots(config.consumption.naturalGas)}
                          variant={'standard'}
                        />
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <Typography style={{ marginLeft: '200px' }}>
                          CO₂-Äquivalent in t{' '}
                          <strong className={classes.bold}>
                            {digitGrouping(equivalents.consumption.naturalGas)}
                          </strong>
                        </Typography>
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          label={'Heizöl in l'}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                          }}
                          onChange={e => {
                            const { consumption } = config
                            consumption.oilHeating = removeDots(e.target.value)
                            equivalents.consumption.oilHeating =
                              consumption.oilHeating * factors.oilHeating || 0

                            setConfig({ ...config, consumption })
                            setEquivalents({
                              ...equivalents,
                              consumption: equivalents.consumption,
                            })
                          }}
                          //type={'number'}
                          value={insertDots(config.consumption.oilHeating)}
                          variant={'standard'}
                        />
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <Typography style={{ marginLeft: '200px' }}>
                          CO₂-Äquivalent in t{' '}
                          <strong className={classes.bold}>
                            {digitGrouping(equivalents.consumption.oilHeating)}
                          </strong>
                        </Typography>
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          label={'Fernwärme in kWh'}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                          }}
                          onChange={e => {
                            const { consumption } = config
                            consumption.teleHeating = removeDots(e.target.value)
                            equivalents.consumption.teleHeating =
                              consumption.teleHeating * factors.teleHeating || 0

                            setConfig({ ...config, consumption })
                            setEquivalents({
                              ...equivalents,
                              consumption: equivalents.consumption,
                            })
                          }}
                          //type={'number'}
                          value={insertDots(config.consumption.teleHeating)}
                          variant={'standard'}
                        />
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <Typography style={{ marginLeft: '200px' }}>
                          CO₂-Äquivalent in t{' '}
                          <strong className={classes.bold}>
                            {digitGrouping(equivalents.consumption.teleHeating)}
                          </strong>
                        </Typography>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {/* Kraftstoffbezogene Emissionen */}
                {subTab === 1 && (
                  <Grid container>
                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          label={'Diesel in l'}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                          }}
                          onChange={e => {
                            const { emissions } = config
                            emissions.diesel = removeDots(e.target.value)
                            equivalents.emissions.diesel =
                              emissions.diesel * factors.diesel || 0

                            setConfig({ ...config, emissions })
                            setEquivalents({
                              ...equivalents,
                              emissions: equivalents.emissions,
                            })
                          }}
                          //type={'number'}
                          value={insertDots(config.emissions.diesel)}
                          variant={'standard'}
                        />
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <Typography style={{ marginLeft: '200px' }}>
                          CO₂-Äquivalent in t{' '}
                          <strong className={classes.bold}>
                            {digitGrouping(equivalents.emissions.diesel)}
                          </strong>
                        </Typography>
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          label={'Benzin in l'}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                          }}
                          onChange={e => {
                            const { emissions } = config
                            emissions.benzine = removeDots(e.target.value)
                            equivalents.emissions.benzine =
                              emissions.benzine * factors.benzine || 0

                            setConfig({ ...config, emissions })
                            setEquivalents({
                              ...equivalents,
                              emissions: equivalents.emissions,
                            })
                          }}
                          //type={'number'}
                          value={insertDots(config.emissions.benzine)}
                          variant={'standard'}
                        />
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <Typography style={{ marginLeft: '200px' }}>
                          CO₂-Äquivalent in t{' '}
                          <strong className={classes.bold}>
                            {digitGrouping(equivalents.emissions.benzine)}
                          </strong>
                        </Typography>
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          label={'Bahn / ÖPNV in km'}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                          }}
                          onChange={e => {
                            const { emissions } = config
                            emissions.publicTransport = removeDots(
                              e.target.value
                            )
                            equivalents.emissions.publicTransport =
                              emissions.publicTransport *
                                factors.publicTransport || 0

                            setConfig({ ...config, emissions })
                            setEquivalents({
                              ...equivalents,
                              emissions: equivalents.emissions,
                            })
                          }}
                          //type={'number'}
                          value={insertDots(config.emissions.publicTransport)}
                          variant={'standard'}
                        />
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <Typography style={{ marginLeft: '200px' }}>
                          CO₂-Äquivalent in t{' '}
                          <strong className={classes.bold}>
                            {digitGrouping(
                              equivalents.emissions.publicTransport
                            )}
                          </strong>
                        </Typography>
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          label={'Anzahl Kurzstreckenflüge (< 3h)'}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                          }}
                          onChange={e => {
                            const { emissions } = config
                            emissions.shortHaulFlight = removeDots(
                              e.target.value
                            )
                            equivalents.emissions.shortHaulFlight =
                              emissions.shortHaulFlight *
                                factors.shortHaulFlight || 0

                            setConfig({ ...config, emissions })
                            setEquivalents({
                              ...equivalents,
                              emissions: equivalents.emissions,
                            })
                          }}
                          //type={'number'}
                          value={insertDots(config.emissions.shortHaulFlight)}
                          variant={'standard'}
                        />
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <Typography style={{ marginLeft: '200px' }}>
                          CO₂-Äquivalent in t{' '}
                          <strong className={classes.bold}>
                            {digitGrouping(
                              equivalents.emissions.shortHaulFlight
                            )}
                          </strong>
                        </Typography>
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          label={'Anzahl Mittelstreckenflüge (3-6h)'}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                          }}
                          onChange={e => {
                            const { emissions } = config
                            emissions.mediumHaulFlight = removeDots(
                              e.target.value
                            )
                            equivalents.emissions.mediumHaulFlight =
                              emissions.mediumHaulFlight *
                                factors.mediumHaulFlight || 0

                            setConfig({ ...config, emissions })
                            setEquivalents({
                              ...equivalents,
                              emissions: equivalents.emissions,
                            })
                          }}
                          //type={'number'}
                          value={insertDots(config.emissions.mediumHaulFlight)}
                          variant={'standard'}
                        />
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <Typography style={{ marginLeft: '200px' }}>
                          CO₂-Äquivalent in t{' '}
                          <strong className={classes.bold}>
                            {digitGrouping(
                              equivalents.emissions.mediumHaulFlight
                            )}
                          </strong>
                        </Typography>
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          label={'Anzahl Langstreckenflüge (> 6h)'}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                          }}
                          onChange={e => {
                            const { emissions } = config
                            emissions.longHaulFlight = removeDots(
                              e.target.value
                            )
                            equivalents.emissions.longHaulFlight =
                              emissions.longHaulFlight *
                                factors.longHaulFlight || 0

                            setConfig({ ...config, emissions })
                            setEquivalents({
                              ...equivalents,
                              emissions: equivalents.emissions,
                            })
                          }}
                          //type={'number'}
                          value={insertDots(config.emissions.longHaulFlight)}
                          variant={'standard'}
                        />
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <Typography style={{ marginLeft: '200px' }}>
                          CO₂-Äquivalent in t{' '}
                          <strong className={classes.bold}>
                            {digitGrouping(
                              equivalents.emissions.longHaulFlight
                            )}
                          </strong>
                        </Typography>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {/* Material & Sonstiges */}
                {subTab === 2 && (
                  <Grid container>
                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          label={'Papier in kg'}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                          }}
                          onChange={e => {
                            const { material } = config
                            material.paper = removeDots(e.target.value)
                            equivalents.material.paper =
                              material.paper * factors.paper || 0

                            setConfig({ ...config, material })
                            setEquivalents({
                              ...equivalents,
                              material: equivalents.material,
                            })
                          }}
                          //type={'number'}
                          value={insertDots(config.material.paper)}
                          variant={'standard'}
                        />
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <Typography style={{ marginLeft: '200px' }}>
                          CO₂-Äquivalent in t{' '}
                          <strong className={classes.bold}>
                            {digitGrouping(equivalents.material.paper)}
                          </strong>
                        </Typography>
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          label={'Abfall in kg'}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                          }}
                          onChange={e => {
                            const { material } = config
                            material.waste = removeDots(e.target.value)
                            equivalents.material.waste =
                              material.waste * factors.waste || 0

                            setConfig({ ...config, material })
                            setEquivalents({
                              ...equivalents,
                              material: equivalents.material,
                            })
                          }}
                          //type={'number'}
                          value={insertDots(config.material.waste)}
                          variant={'standard'}
                        />
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <Typography style={{ marginLeft: '200px' }}>
                          CO₂-Äquivalent in t{' '}
                          <strong className={classes.bold}>
                            {digitGrouping(equivalents.material.waste)}
                          </strong>
                        </Typography>
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={12}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          helperText={
                            'Emissionen aus Transport und Verteilung von Waren von Zulieferern'
                          }
                          label={'Vorgelagerte Aktivitäten in Tonnen CO₂'}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                          }}
                          onChange={e => {
                            const { material } = config
                            material.supplier = removeDots(e.target.value)
                            equivalents.material.supplier =
                              material.supplier || 0

                            setConfig({ ...config, material })
                            setEquivalents({
                              ...equivalents,
                              material: equivalents.material,
                            })
                          }}
                          //type={'number'}
                          value={insertDots(config.material.supplier)}
                          variant={'standard'}
                        />
                      </FormControl>
                    </Grid>

                    <Grid className={classes.grid} item xs={12}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          helperText={
                            'Emissionen aus Transport und Verteilung von Waren an Abnehmer'
                          }
                          label={'Nachgelagerte Aktivitäten in Tonnen CO₂'}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                          }}
                          onChange={e => {
                            const { material } = config
                            material.customer = removeDots(e.target.value)
                            equivalents.material.customer =
                              material.customer || 0

                            setConfig({ ...config, material })
                            setEquivalents({
                              ...equivalents,
                              material: equivalents.material,
                            })
                          }}
                          //type={'number'}
                          value={insertDots(config.material.customer)}
                          variant={'standard'}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {/* Klima-Invest */}
                {subTab === 3 && (
                  <Grid container>
                    <Grid className={classes.grid} item xs={12}>
                      <FormControl className={classes.formControl} fullWidth>
                        <Typography>
                          Bereits kompensierte Menge an CO₂-Emissionen aus
                          bestehenden Klimaschutzzertifikaten oder -projekten in
                          Tonnen CO₂
                        </Typography>
                        <TextField
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                          }}
                          label={'Menge in Tonnen CO₂'}
                          onChange={e => {
                            const climateInvest = removeDots(e.target.value)
                            equivalents.climateInvest = climateInvest || 0

                            setConfig({
                              ...config,
                              climateInvest,
                            })
                            setEquivalents({
                              ...equivalents,
                              climateInvest,
                            })
                          }}
                          //type={'number'}
                          value={insertDots(config.climateInvest)}
                          variant={'standard'}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                <Typography className={classes.notice2}>
                  Alle nicht ausgefüllten Felder werden nicht in die Berechnung
                  einbezogen.
                </Typography>
              </>
            </div>
          </ListItem>
          {/* CO2-Footprint */}
          <ListItem>
            <Typography className={classes.curSlide}>{tab + 1}.</Typography>

            <div className={classes.slide} style={{ overflow: 'auto' }}>
              <Typography
                className={classes.header}
                component={'h4'}
                variant={'h4'}
              >
                CO₂-Footprint
              </Typography>

              <Typography component={'h6'} variant={'h6'}>
                Ergebnis
              </Typography>

              <Typography
                className={classes.section}
                component={'h6'}
                variant={'h6'}
              >
                Die in diesem Projekt erfassten Treibhausgas relevanten Prozesse
                führen zu einem <i>Carbon Footprint</i> von{' '}
                {Number(saldo).toLocaleString(userLocales, precision)}
                Tonnen CO₂e.
              </Typography>

              <TableContainer component={Paper} elevation={0}>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.bold}>
                        Projektname
                      </TableCell>
                      <TableCell className={classes.bold} align={'right'}>
                        {/*meta[0].company*/}
                        {company}
                      </TableCell>
                    </TableRow>

                    {/*<TableRow>
                      <TableCell className={classes.bold}>Bezugsjahr</TableCell>
                      <TableCell className={classes.bold} align={'right'}>
                        2021
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.bold}>
                        Berechnungsstandard
                      </TableCell>
                      <TableCell className={classes.bold} align={'right'}>
                        vertraglich
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.bold}>Region</TableCell>
                      <TableCell className={classes.bold} align={'right'}>
                        Deutschland
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.bold}>Profil</TableCell>
                      <TableCell className={classes.bold} align={'right'}>
                        WM Demoversion
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Erstellungsdatum</TableCell>
                      <TableCell align={'right'}>
                        {new Date(Date.now()).toISOString()}
                      </TableCell>
                    </TableRow>*/}
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography component={'h6'} variant={'h6'}>
                Ergebnis der einzelnen Sektoren
              </Typography>

              <TableContainer component={Paper} elevation={0}>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow className={classes.section}>
                      <TableCell colSpan={2} align={'right'}>
                        CO₂e [t]
                      </TableCell>
                      <TableCell align={'right'}>CO₂e [%]</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.bold}>
                        Energiedaten
                      </TableCell>
                      <TableCell className={classes.bold} align={'right'}>
                        {Number(energyData.total).toLocaleString(
                          userLocales,
                          precision
                        )}
                      </TableCell>
                      <TableCell className={classes.bold} align={'right'}>
                        {Number(
                          (energyData.total / saldo) * 100
                        ).toLocaleString(userLocales, precision)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>&gt;Wärme</TableCell>
                      <TableCell align={'right'}>
                        {Number(energyData.heating).toLocaleString(
                          userLocales,
                          precision
                        )}
                      </TableCell>
                      <TableCell align={'right'}>
                        {Number(
                          (energyData.heating / saldo) * 100
                        ).toLocaleString(userLocales, precision)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>&gt;Strom</TableCell>
                      <TableCell align={'right'}>
                        {Number(energyData.electricity).toLocaleString(
                          userLocales,
                          precision
                        )}
                      </TableCell>
                      <TableCell align={'right'}>
                        {Number(
                          (energyData.electricity / saldo) * 100
                        ).toLocaleString(userLocales, precision)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.bold}>
                        Kraftstoffbezogene Emissionen
                      </TableCell>
                      <TableCell className={classes.bold} align={'right'}>
                        {Number(emissionsData.total).toLocaleString(
                          userLocales,
                          precision
                        )}
                      </TableCell>
                      <TableCell className={classes.bold} align={'right'}>
                        {Number(
                          (emissionsData.total / saldo) * 100
                        ).toLocaleString(userLocales, precision)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>&gt;Fuhrpark</TableCell>
                      <TableCell align={'right'}>
                        {Number(emissionsData.motorPool).toLocaleString(
                          userLocales,
                          precision
                        )}
                      </TableCell>
                      <TableCell align={'right'}>
                        {Number(
                          (emissionsData.motorPool / saldo) * 100
                        ).toLocaleString(userLocales, precision)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>&gt;Geschäftsfahrten</TableCell>
                      <TableCell align={'right'}>
                        {Number(emissionsData.businessTrips).toLocaleString(
                          userLocales,
                          precision
                        )}
                      </TableCell>
                      <TableCell align={'right'}>
                        {Number(
                          (emissionsData.businessTrips / saldo) * 100
                        ).toLocaleString(userLocales, precision)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.bold}>
                        Material &amp; Sonstiges
                      </TableCell>
                      <TableCell className={classes.bold} align={'right'}>
                        {Number(materialData.total).toLocaleString(
                          userLocales,
                          precision
                        )}
                      </TableCell>
                      <TableCell className={classes.bold} align={'right'}>
                        {Number(
                          (materialData.total / saldo) * 100
                        ).toLocaleString(userLocales, precision)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>&gt;Papier</TableCell>
                      <TableCell align={'right'}>
                        {Number(materialData.paper).toLocaleString(
                          userLocales,
                          precision
                        )}
                      </TableCell>
                      <TableCell align={'right'}>
                        {Number(
                          (materialData.paper / saldo) * 100
                        ).toLocaleString(userLocales, precision)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography component={'h6'} variant={'h6'}>
                Ergebnis nach Geltungsbereich
              </Typography>

              <TableContainer component={Paper} elevation={0}>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow className={classes.section}>
                      <TableCell>Ergebnis</TableCell>
                      <TableCell align={'right'}>CO₂e [t]</TableCell>
                      <TableCell align={'right'}>CO₂e [%]</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Direkte Emissionen (Scope 1)</TableCell>
                      <TableCell align={'right'}>
                        {Number(scope1).toLocaleString(userLocales, precision)}
                      </TableCell>
                      <TableCell align={'right'}>
                        {Number(
                          (scope1 / (scope1 + scope2 + scope3)) * 100
                        ).toLocaleString(userLocales, precision)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        Indirekte Emissionen aus bereitgestellter Energie (Scope
                        2)
                      </TableCell>
                      <TableCell align={'right'}>
                        {Number(scope2).toLocaleString(userLocales, precision)}
                      </TableCell>
                      <TableCell align={'right'}>
                        {Number(
                          (scope2 / (scope1 + scope2 + scope3)) * 100
                        ).toLocaleString(userLocales, precision)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        Weitere indirekte Emissionen (Scope 3)
                      </TableCell>
                      <TableCell align={'right'}>
                        {Number(scope3).toLocaleString(userLocales, precision)}
                      </TableCell>
                      <TableCell align={'right'}>
                        {Number(
                          (scope3 / (scope1 + scope2 + scope3)) * 100
                        ).toLocaleString(userLocales, precision)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.bold}>
                        Gesamte Emissionen
                      </TableCell>
                      <TableCell className={classes.bold} align={'right'}>
                        {Number(saldo).toLocaleString(userLocales, precision)}
                      </TableCell>
                      <TableCell className={classes.bold} align={'right'}>
                        100,00
                      </TableCell>
                    </TableRow>

                    <TableRow className={classes.section}>
                      <TableCell colSpan={2} align={'right'}>
                        CO₂e [t]
                      </TableCell>
                      <TableCell align={'right'}>CO₂e [%]</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.bold}>
                        Klima-Invest
                      </TableCell>
                      <TableCell className={classes.bold} align={'right'}>
                        -{' '}
                        {Number(config.climateInvest).toLocaleString(
                          userLocales,
                          precision
                        )}
                      </TableCell>
                      <TableCell className={classes.bold} align={'right'}>
                        -{' '}
                        {Number(
                          (config.climateInvest / saldo) * 100
                        ).toLocaleString(userLocales, precision)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <FormControl className={classes.header} fullWidth>
                <Typography component={'h4'} variant={'h4'}>
                  Verteilung der CO₂-Emissionen nach Kategorie
                </Typography>
              </FormControl>

              <Chart
                chartType={'ColumnChart'}
                data={chartData}
                height={'300px'}
                loader={<>bitte warten...</>}
                options={{ legend: 'none', backgroundColor: 'none' }}
              />

              <Chart
                chartType={'PieChart'}
                data={pieData}
                height={'300px'}
                loader={<>bitte warten...</>}
                options={{ is3D: true, backgroundColor: 'none' }}
              />
            </div>
          </ListItem>
          {/* Neutralstellung/Kompensation */}
          {/*<ListItem>
            <Typography className={classes.curSlide}>{tab + 1}.</Typography>

            <div className={classes.slide}>
              <Container>
                <Grid container>
                  <Grid item xs={3}>
                    <Card
                      className={classes.card}
                      onClick={() =>
                        setConfig({ ...config, compensationMethod: 1 })
                      }
                    >
                      <CardActionArea>
                        <CardMedia
                          component={'img'}
                          height={150}
                          image={COMPENSATION_0}
                          title={''}
                        />

                        <CardContent>
                          <Typography>teaser text günstig</Typography>
                          <Button color={'primary'} variant={'outlined'}>
                            5 € je Tonne
                          </Button>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>

                  <Grid item xs={3}>
                    <Card
                      className={classes.card}
                      onClick={() =>
                        setConfig({ ...config, compensationMethod: 2 })
                      }
                    >
                      <CardActionArea>
                        <CardMedia
                          component={'img'}
                          height={150}
                          image={COMPENSATION_1}
                          title={''}
                        />
                        <CardContent>
                          <Typography>teaser text mittel</Typography>
                          <Button color={'primary'} variant={'outlined'}>
                            10 € je Tonne
                          </Button>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>

                  <Grid item xs={3}>
                    <Card
                      className={classes.card}
                      onClick={() =>
                        setConfig({ ...config, compensationMethod: 3 })
                      }
                    >
                      <CardActionArea>
                        <CardMedia
                          component={'img'}
                          height={150}
                          image={COMPENSATION_2}
                          title={''}
                        />
                        <CardContent>
                          <Typography>teaser text hochwertig</Typography>
                          <Button color={'primary'} variant={'outlined'}>
                            20 € je Tonne
                          </Button>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>

                  <Grid item xs={3}>
                    <Card
                      className={classes.card}
                      onClick={() =>
                        setConfig({ ...config, compensationMethod: 4 })
                      }
                    >
                      <CardActionArea>
                        <CardMedia
                          component={'img'}
                          height={150}
                          image={COMPENSATION_3}
                          title={''}
                        />
                        <CardContent>
                          <Typography>
                            teaser text CO₂-Neutralstellung mit
                            TÜV-Zertifizierung
                          </Typography>
                          <a
                            href={'https://karma.de'}
                            rel={'noreferrer'}
                            target={'_blank'}
                          >
                            Warum wichtig?
                          </a>
                          <br />
                          <br />
                          <Button color={'primary'} variant={'outlined'}>
                            Mehr erfahren
                          </Button>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                    </Grid>

                <Container className={classes.footer}>
                  <Button
                    onClick={() => setShowRequestOffer(true)}
                    className={classes.button}
                  >
                    Angebot anfordern
                  </Button>
                </Container>
              </Container>
            </div>
          </ListItem>*/}
          {/* Abgebot/Abschluss */}
          <ListItem>
            <div className={classes.slide}>Angebot</div>
          </ListItem>
        </Stack>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {/* ZURÜCK */}
          <Button
            className={classes.btnBack}
            disabled={tab === 0}
            onClick={() => {
              if (tab === 1) {
                if (subTab === 0) setTab(tab => tab - 1)
                else setSubTab(tab => tab - 1)
              } else setTab(tab => tab - 1)
            }}
          >
            Zurück
          </Button>
          {/* WEITER */}
          <Button
            className={
              tab === 1 && subTab === 3
                ? classes.button
                : tab === 2 ||
                  (tab === 0 && !isValidLength(name, 2)) ||
                  !isValidLength(company, 2)
                  ? classes.btnForwardDisabled
                  : classes.btnForward
            }
            disabled={
              tab === 2 ||
              ((tab === 0 && !isValidLength(name, 2)) ||
                !isValidLength(company, 2))
            }
            onClick={() => {
              if (tab === 1) {
                if (subTab === 3) {
                  //setTab(tab => tab + 1)
                  setShowRequestOffer(true)
                } else setSubTab(tab => tab + 1)
              } else setTab(tab => tab + 1)
            }}
          >
            {tab === 1 && subTab === 3 ? 'Footprint anfordern' : 'Weiter'}
          </Button>
        </div>
      </Container>
    </div>
  )
}
