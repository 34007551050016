// modules
import { Collapse, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useContext } from 'react'
import {
  Downloads,
  //Faq,
  Footer,
  Newsletter,
  Potential,
  IndividualRequest,
  SingleText,
} from './components'
import EnergyContext from '../context/energy.context'
import { ICO_BIG_COMMENT } from '../img'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#FFF !important',
    margin: '0 auto',
    width: '100%',
    minWidth: '800px',
    maxWidth: '1920px',
    maxHeight: '1080px',
  },
}))

export const EnergyCalculator = () => {
  const classes = useStyles()
  const data = useContext(EnergyContext)
  const { downloads, /*faq,*/ footer, potential, text } = data

  return (
    <Paper className={classes.root} elevation={0}>
      {/* Single Text */}
      {text && (
        <Collapse in={true}>
          <SingleText data={text} />
        </Collapse>
      )}

      {/* Potential */}
      {potential && (
        <Collapse in={true}>
          <Potential data={potential} />
        </Collapse>
      )}

      {/* IndividualRequest */}
      <Collapse in={true}>
        <IndividualRequest icon={ICO_BIG_COMMENT} />
      </Collapse>

      {/* Downloads */}
      {downloads && (
        <Collapse in={true}>
          <Downloads data={downloads} />
        </Collapse>
      )}

      {/* FAQ */}
      {/*faq && (
        <Collapse in={true}>
          <Faq data={faq} title={'FAQ Strom'} />
        </Collapse>
      )*/}

      {/* Newsletter */}
      <Collapse in={true}>
        <Newsletter />
      </Collapse>

      {/* Footer */}
      <Footer data={footer} />
    </Paper>
  )
}
