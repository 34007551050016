import {
  Button,
  Container,
  FormControl,
  TextField,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import { isValidEmail } from '../../api/util'

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    background: '#A00523',
    color: 'white',
    width: '100%',
    maxWidth: '1920px',
    height: '110px',
  },

  title: {
    font: '40px RobotoSlab !important',
    position: 'absolute',
    top: '30px',
  },

  textfield: {
    color: 'white !important',
    top: '35px',
    left: '400px',
    width: '550px',
    marginRight: '20px',
  },

  button: {
    background: 'white !important',
    color: '#A00523',
    left: '500px',
    top: '35px',
    width: '300px',
  },
}))

export const Newsletter = () => {
  const classes = useStyles()

  const [email, setEmail] = useState('')

  return (
    <div className={classes.root}>
      <form
        action={'https://seu2.cleverreach.com/f/289041-299168/wcs/'}
        method={'post'}
      >
        <input type={'hidden'} name={'1079318'} value={'Vorname'} />
        <input type={'hidden'} name={'1079317'} value={'Name'} />

        <Container maxWidth={'xl'}>
          <Typography className={classes.title}>Newsletter</Typography>

          <FormControl className={classes.textfield}>
            <TextField
              InputLabelProps={{ className: classes.textfield }}
              inputProps={{ style: { color: 'white' } }}
              label={'E-Mail'}
              name={'email'}
              onChange={e => setEmail(e.target.value)}
              sx={{
                '& .MuiInput-underline:before': { borderBottomColor: 'white' },
                '& .MuiInput-underline:after': { borderBottomColor: 'white' },
              }}
              value={email}
              variant={'standard'}
            />
          </FormControl>

          <Button
            className={classes.button}
            disabled={!isValidEmail(email)}
            type={'submit'}
          >
            Newsletter abonnieren
          </Button>
        </Container>
      </form>
    </div>
  )
}
