export const isValidEmail = value => {
  const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return regExp.test(value) ? true : false
}

export const isValidLength = (val, len) => val && val.length >= len

export const isValidPassword = value => {
  const regExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/

  return regExp.test(value) ? true : false
}
