// modules
import { Container, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useContext } from 'react'
import ReactMarkdown from 'react-markdown'
// scripts
import { Footer } from './components'
// context
import BlogContext from '../context/blog.context'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#FFF !important',
    margin: '0 auto',
    width: '100%',
    minWidth: '800px',
    maxWidth: '1920px',
    maxHeight: '1080px',
  },
  imageList: {
    marginTop: '150px',
  },
  content: {
    font: '20px Roboto !important',
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
    textAlign: 'justify',
  },
  released: {
    font: '20px Roboto !important',
    borderBottom: 'solid 1px black',
    width: '100%',
    textAlign: 'right',
    paddingBottom: '10px',
    fontStyle: 'italic',
  },
}))

export const BlogSingle = () => {
  const classes = useStyles()
  const blogData = useContext(BlogContext)
  const { header } = blogData
  const { slug } = useParams()
  const { footer, blogItems } = header
  const { REACT_APP_BASE_URL: BASE_URL } = process.env

  if (!blogData) return <div />

  const blogContent = blogItems.find(f => f.slug === slug)
  if (!blogContent) return <div>404</div>

  return (
    <Paper className={classes.root} elevation={0}>
      <Container className={classes.imageList}>
        <Typography className={classes.released}>
          {new Date(blogContent.released).toLocaleDateString('de-DE', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })}
        </Typography>
        <ReactMarkdown
          className={classes.content}
          components={{
            em: ({ ...props }) => <i style={{ color: 'red' }} {...props} />,
            strong: ({ ...props }) => (
              <span style={{ fontFamily: 'RobotoBlack' }} {...props} />
            ),
            img: ({ node, ...props }) => {
              return (
                <img
                  {...props}
                  alt={node.item}
                  src={BASE_URL + node.properties.src}
                />
              )
            },
          }}
        >
          {blogContent.content}
        </ReactMarkdown>
      </Container>

      {/* Footer */}
      <Footer data={footer} />
    </Paper>
  )
}
