import { Container, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useContext } from 'react'
import { Footer } from './components/Footer'
//
import HomeContext from '../context/home.context'
import PrivacyContext from '../context/privacy.context'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#FFF !important',
    margin: '0 auto',
    width: '100%',
    minWidth: '800px',
    maxWidth: '1920px',
    maxHeight: '1080px',
  },
  title: {
    font: '40px RobotoSlab !important',
    paddingTop: '50px !important',
    marginBottom: '20px !important',
  },
  description: {
    font: '20px Roboto !important',
    paddingTop: '50px !important',
    marginBottom: '20px !important',
    justifyContent: 'space-between',
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
    textAlign: 'justify',
  },
}))

export const Privacy = () => {
  const classes = useStyles()
  const data = useContext(PrivacyContext)
  const homeFooter = useContext(HomeContext)

  return (
    <Paper className={classes.root} elevation={0}>
      <Container>
        <Typography className={classes.title}>{data.title}</Typography>
        <Typography className={classes.description}>
          {data.description}
        </Typography>
      </Container>

      {/* Footer */}
      <Footer data={homeFooter.footer} />
    </Paper>
  )
}
