import { Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useContext } from 'react'
//
import {
  Contact,
  Footer,
  Newsletter,
  OurValues,
  SingleText,
} from './components'
import CompanyContext from '../context/company.context'
// assets
import { ICO_QUESTION } from '../img'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#FFF !important',
    margin: '0 auto',
    width: '100%',
    minWidth: '800px',
    maxWidth: '1920px',
    maxHeight: '1080px',
  },
}))

export const TheCompany = () => {
  const classes = useStyles()
  const data = useContext(CompanyContext)

  return (
    <Paper className={classes.root} elevation={0}>
      {/* SingleText */}
      <SingleText data={data.text} />

      {/* AuditDIN */}
      {/*<AuditDIN data={data.auditDin} />*/}

      {/* AdvantagesOA */}
      {/*<AdvantagesOA data={data.advantages} />*/}

      {/* Our Values (Expectations component) */}
      {<OurValues data={data.corporateValues} icon={ICO_QUESTION} />}

      {/* Contact */}
      {<Contact data={data.contact} />}

      {/* Newsletter */}
      <Newsletter />

      {/* Footer */}
      <Footer data={data.footer} />
    </Paper>
  )
}
