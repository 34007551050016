// modules
import { Button, Collapse } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
// assets
import {
  ICO_PHONE,
  ICO_MAIL,
  //ICO_USER,
  ICO_LOGO,
} from '../../img'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    display: 'flex',
    width: '100%',
    minWidth: '1200px',
    maxWidth: '1920px',
    height: '90px',
    justifyContent: 'space-between',
    position: 'fixed',
    zIndex: '999998',
  },

  we_logo: {
    marginLeft: '200px',
    marginTop: '20px',
  },

  container: {
    marginRight: '200px',
    marginTop: '32px',
  },

  phone: { marginRight: '8px' },
  mail: { marginRight: '8px' },
  contact: { color: '#1D1D1B' },

  menuBar: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '90px',
    padding: '0px 0px 0px 300px',
    zIndex: '999999',
    width: '100%',
    minWidth: '1200px',
    maxWidth: '1620px',
  },

  menuLink: {
    font: '20px Roboto',
    color: '#1D1D1B',
    textDecoration: 'none',
    padding: '10px',
    '&:hover': {
      color: '#A00523',
    },
    marginBottom: '10px',
  },

  menuLinkSelected: {
    font: '20px RobotoBlack',
    color: '#1D1D1B',
    textDecoration: 'none',
    padding: '10px',
    '&:hover': {
      color: '#A00523',
    },
  },
}))

export const Header = () => {
  const classes = useStyles()
  const location = useLocation()

  const [showMenu, setShowMenu] = useState(false)

  return (
    <>
      <div className={classes.root} onMouseOver={() => setShowMenu(true)}>
        <Link to={'/'}>
          <img className={classes.we_logo} src={ICO_LOGO} alt={''} />
        </Link>
        <div className={classes.container}>
          <Button>
            <img alt={''} className={classes.phone} src={ICO_PHONE} />
            <span className={classes.contact}>0 7141 25878 - 140</span>
          </Button>
          <Button>
            <img alt={''} className={classes.mail} src={ICO_MAIL} />
            <span
              className={classes.contact}
              onClick={() => (window.location = 'mailto:info@wb-energie.de')}
            >
              info@wb-energie.de
            </span>
          </Button>
          {/*<Button>
            <img alt={''} src={ICO_USER} />
          </Button>*/}
        </div>
      </div>
      <Collapse
        in={showMenu}
        className={classes.menuBar}
        onMouseOut={e => {
          if (e.clientY > 110) setShowMenu(false)
        }}
      >
        <Link
          className={
            location.pathname === '/energy-calculator'
              ? classes.menuLinkSelected
              : classes.menuLink
          }
          to={'/energy-calculator'}
        >
          Strom
        </Link>
        <Link
          className={
            location.pathname === '/gas-calculator'
              ? classes.menuLinkSelected
              : classes.menuLink
          }
          to={'/gas-calculator'}
        >
          Gas
        </Link>
        <Link
          className={
            location.pathname === '/co2-calculator'
              ? classes.menuLinkSelected
              : classes.menuLink
          }
          to={'/co2-calculator'}
        >
          CO2
          {/*₂*/}
        </Link>
        <Link
          className={
            location.pathname === '/audit'
              ? classes.menuLinkSelected
              : classes.menuLink
          }
          to={'/audit'}
        >
          Online Audit
        </Link>
        <Link
          className={
            location.pathname === '/foerdermittel'
              ? classes.menuLinkSelected
              : classes.menuLink
          }
          to={'/foerdermittel'}
        >
          Fördermittel
        </Link>
        <Link
          className={
            location.pathname === '/unternehmen'
              ? classes.menuLinkSelected
              : classes.menuLink
          }
          to={'/unternehmen'}
        >
          Unternehmen
        </Link>
        <Link
          className={
            location.pathname === '/blog'
              ? classes.menuLinkSelected
              : classes.menuLink
          }
          to={'/blog'}
        >
          Blog
        </Link>
      </Collapse>
    </>
  )
}
