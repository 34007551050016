import {
  Collapse,
  Container,
  ImageList,
  ImageListItem,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { ICO_WHITE_ARROW_RIGHT } from '../../img'

const useStyles = makeStyles(() => ({
  root: {
    background: '#FFF',
    paddingTop: '50px',
    paddingBottom: '50px',
  },
  title: {
    font: '40px RobotoSlab !important',
    maxWidth: '765px',
    marginBottom: '50px !important',
  },
  content: { backgroundColor: '#A00523', padding: '80px', color: '#FFF' },
  itemDescription: { textAlign: 'justify' },
}))

export const AuditDIN = props => {
  const classes = useStyles()
  const { data } = props
  if (!data) return <div />

  const { items, title } = data

  const [active, setActive] = useState(null)

  return data ? (
    <div className={classes.root}>
      <Container>
        <Typography className={classes.title}>{title}</Typography>
      </Container>

      <ImageList xl={{ width: 1920, height: 440 }} cols={3} rowHeight={440}>
        {items.map((item, key) => {
          const { description, title } = item

          return (
            <ImageListItem
              key={key}
              className={classes.content}
              onMouseOver={() => setActive(key)}
              onMouseOut={() => setActive(null)}
            >
              <Typography className={classes.title}>
                {title}
                <br />
                <img
                  alt={''}
                  src={ICO_WHITE_ARROW_RIGHT}
                  width={30}
                  height={30}
                />
              </Typography>
              <Collapse in={active === key} className={classes.itemDescription}>
                <Typography className={classes.description}>
                  {description}
                </Typography>
              </Collapse>
            </ImageListItem>
          )
        })}
      </ImageList>
    </div>
  ) : (
    ''
  )
}

AuditDIN.propTypes = {
  data: PropTypes.object,
}
