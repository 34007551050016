import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { Layout } from './layouts'
import { routes } from './routes'
import { createBrowserHistory } from 'history'

export const App = () => {
  const history = createBrowserHistory()

  return (
    <Router history={history}>
      <Switch>
        <Route component={Layout} path={routes.path} />
      </Switch>
    </Router>
  )
}
