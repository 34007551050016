import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PropTypes } from 'prop-types'
import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useOnScreen } from '../../hooks/useOnScreen'
import CountUp from 'react-countup'

const useStyles = makeStyles(() => ({
  root: {
    background: '#FFF',
    width: '100%',
    maxWidth: '1920px !important',
    paddingBottom: '50px !important',
  },
  caption: {
    font: '40px RobotoSlab !important',
    marginBottom: '50px !important',
  },

  title: {
    font: '120px RobotoSlab !important',
  },

  description: {
    font: '14px Roboto !important',
    textTransform: 'uppercase !important',
    letterSpacing: '3px',
    width: '270px',
  },

  more: {
    font: '20px Roboto !important',
    backgroundColor: 'transparent',
    border: 'solid 1px white !important',
    color: 'white !important',
    left: '800px',
    margin: '0px !important',
    position: 'absolute',
    width: '320px',
    top: '750px',
  },
}))

export const Company = props => {
  const classes = useStyles()
  const history = useHistory()

  const ref = useRef()
  const isVisible = useOnScreen(ref)

  const { data } = props
  const { background, blur, brightness, title } = data
  const { REACT_APP_BASE_URL: BASE_URL } = process.env

  return data ? (
    <div className={classes.root}>
      <Container>
        <Typography className={classes.caption}>{title}</Typography>
      </Container>

      <Card style={{ position: 'relative' }}>
        <div style={{ filter: `blur(${blur}px)`, transform: 'scale(1.1)' }}>
          <CardMedia
            ref={ref}
            component={'img'}
            height={'965'}
            image={`${BASE_URL}${background.url}`}
            style={{ filter: `brightness(${brightness})` }}
          />
        </div>
        <CardContent
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            color: 'white',
          }}
        >
          <Grid container>
            <Grid item xs={3} style={{ padding: '0px' }}>
              <div
                style={{ position: 'absolute', left: '200px', top: '150px' }}
              >
                {isVisible && (
                  <CountUp
                    className={classes.title}
                    decimal={','}
                    decimals={1}
                    end={1.4}
                    suffix={' Mrd.'}
                  />
                )}
                <Typography className={classes.description}>
                  betreutes Volumen für Strom in kWh
                </Typography>
              </div>
              <div
                style={{ position: 'absolute', left: '830px', top: '150px' }}
              >
                {isVisible && (
                  <CountUp
                    className={classes.title}
                    end={1300}
                    separator={'.'}
                  />
                )}
                <Typography className={classes.description}>Kunden</Typography>
              </div>
              <div
                style={{ position: 'absolute', left: '1220px', top: '150px' }}
              >
                {isVisible && <CountUp className={classes.title} end={6} />}
                <Typography className={classes.description}>
                  2021 umgesetzte CO₂-Neutralstellung
                </Typography>
              </div>
              <div
                style={{ position: 'absolute', left: '1494px', top: '150px' }}
              >
                {isVisible && <CountUp className={classes.title} end={80} />}
                <Typography className={classes.description}>
                  2021 gestellte Förderanträge
                </Typography>
              </div>
              <div
                style={{ position: 'absolute', left: '200px', top: '450px' }}
              >
                {isVisible && (
                  <CountUp
                    className={classes.title}
                    decimal={','}
                    decimals={2}
                    end={1.06}
                    suffix={' Mrd.'}
                  />
                )}
                <Typography className={classes.description}>
                  betreutes Volumen für Gas in kWh
                </Typography>
              </div>
              <div
                style={{ position: 'absolute', left: '830px', top: '450px' }}
              >
                {isVisible && (
                  <CountUp
                    className={classes.title}
                    end={3500}
                    separator={'.'}
                  />
                )}
                <Typography className={classes.description}>
                  Kundenstandorte
                </Typography>
              </div>
              <div
                style={{ position: 'absolute', left: '1220px', top: '450px' }}
              >
                {isVisible && <CountUp className={classes.title} end={7} />}
                <Typography className={classes.description}>
                  2021 erstellte CO₂-Bilanzen
                </Typography>
              </div>
              <div
                style={{ position: 'absolute', left: '1494px', top: '450px' }}
              >
                {isVisible && (
                  <CountUp
                    className={classes.title}
                    decimal={'.'}
                    decimals={1}
                    end={1.5}
                  />
                )}
                <Typography className={classes.description}>
                  bewilligte Fördergelder in Mio. €
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Button
            className={classes.more}
            onClick={() => history.push('/unternehmen')}
          >
            Mehr erfahren
          </Button>
        </CardContent>
      </Card>
    </div>
  ) : (
    ''
  )
}

Company.propTypes = {
  data: PropTypes.object,
}
