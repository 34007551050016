import { useEffect, useState } from 'react'

export const useFetch = url => {
  const { REACT_APP_BASE_URL: BASE_URL } = process.env

  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(
    () => {
      const fetchData = async () => {
        setLoading(true)

        try {
          const res = await fetch(`${BASE_URL}${url}`)
          const json = await res.json()

          setData(json)
          setLoading(false)
        } catch (error) {
          setError(error.message)
          setLoading(false)
        }
      }

      fetchData()
    },
    [url]
  )

  return { loading, error, data }
}
