// modules
import {
  Container,
  Grid,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  Paper,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
// scripts
import { Footer } from './components'
// context
import BlogContext from '../context/blog.context'
// assets
import { IMG_PLACEHOLDER } from '../img'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#FFF !important',
    margin: '0 auto',
    width: '100%',
    minWidth: '800px',
    maxWidth: '1920px',
    maxHeight: '1080px',
  },
  container: {
    marginTop: '150px',
    maxWidth: '765px !important',
    width: '100%',
  },
  title: {
    font: '40px RobotoSlab !important',
    marginBottom: '50px !important',
  },
  link: {
    textAlign: 'right !important',
    textDecoration: 'none !important',
    color: 'black',
  },
  description: {
    font: '20px Roboto !important',
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
    textAlign: 'justify',
  },
}))

export const Blog = () => {
  const classes = useStyles()
  const blogData = useContext(BlogContext)
  const { blogItems, description, footer, title } = blogData.header
  const { REACT_APP_BASE_URL: BASE_URL } = process.env

  if (!blogData) return <div />

  return (
    <Paper className={classes.root} elevation={0}>
      <Container className={classes.container}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.description}>{description}</Typography>
      </Container>

      <Grid container>
        <Grid item xs={2}>
          <List>
            {blogItems.map((item, key) => {
              return (
                <Link
                  to={`/blog/${item.slug}`}
                  key={key}
                  className={classes.link}
                >
                  <ListItem
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    {item.title}
                  </ListItem>
                </Link>
              )
            })}
          </List>
        </Grid>
        <Grid item xs={10}>
          <ImageList
            variant={'masonry'}
            cols={3}
            gap={8}
            sx={{ width: 1008, height: 456 }}
          >
            {blogItems.map((item, key) => {
              const { thumbnail } = item
              const src =
                thumbnail && thumbnail.url
                  ? BASE_URL + thumbnail.url
                  : IMG_PLACEHOLDER

              return (
                <Link to={`/blog/${item.slug}`} key={key}>
                  <ImageListItem>
                    <img src={src} alt={item.title} />
                  </ImageListItem>
                </Link>
              )
            })}
          </ImageList>
        </Grid>
      </Grid>

      {/* Footer */}
      <Footer data={footer} />
    </Paper>
  )
}
